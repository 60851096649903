import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StudentStage } from '../StudentStage';
import SVG from './knowledge-animate.svg';
import Buttonss from '../UI/Buttonss';
import { Badge, Col, Row } from 'react-bootstrap';


const EstudiantesHeader = ({
    level,
url,
}) => {
    const auth = useSelector(state => state.auth);
    return (
        <div className="main">
            <section className="section">

                <div className="section__container--img">
                    <img src={SVG} className="container__img" alt="Libro color naranja flotando con un circulo transparente el la parte de atras" />

                </div>
                {
                    auth.rol !== "profesor" &&
                    <div className='d-flex  flex-column justify-content-center align-items-center'>
                        <h3 className='text-center'>
                            Bienvenu(e) {auth.name}!
                        </h3>
                        <h3 className='text-center'>
                            Votre niveau: <Badge bg={auth.level === "A1" ? "primary" : auth.level === "A2" ? "warning" : auth.level === "B1" || auth.level === "B2" ? "dark" : "danger"}>{auth.level === "A1" ? "Débutant" : auth.level === "A2" ? "Intermédiaire" : auth.level === "B1" || auth.level === "B2" ? "Avancé" : "Club de conversation"}</Badge>
                        </h3>
                    </div>
                }
                <hr />
                {
                    auth?.rol === "club" ?
                        <>
                            <div className="section__container--buttons">
                                <div className="boton_up">
                                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_CLUB}>
                                        Matériel du club de conversation
                                    </Buttonss>
                                </div>
                            </div>
                            <div className="section__container--buttons">

                                <div className="boton_up">
                                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_ALTEREGOA}>
                                        Livres Alter Ego +Audios
                                    </Buttonss>
                                </div>
                                <div className="boton_up">
                                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_DELF}>
                                        Le DELF
                                    </Buttonss>
                                </div>
                            </div>
                            <hr />
                            <div className="section__container--buttons">
                                <div className="boton_up">
                                    <Buttonss type="button" className="button "><Link to={`${url}/lectura`}>Lecture</Link> </Buttonss>
                                    <Buttonss type="button" className="button"><Link to={`${url}/pronunciacion`} >Pronunciation</Link></Buttonss>
                                </div>
                                <div className="boton_down">
                                    <Buttonss type="button" className="button" isLink url={process.env.REACT_APP_DRIVE_PHONETIQUE} > Phonétique</Buttonss>
                                    <Buttonss type="button" className="button"> <Link to={`${url}/comprension-oral`}>Compréhension</Link> </Buttonss>
                                </div>
                            </div>
                        </>
                        :
                        <StudentStage level={level} url={url} />
                }
            </section>
        </div>)
}

export default EstudiantesHeader;