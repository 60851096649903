import brachettiAudio from '../../../audios/Brachetti.mp3';
import { Brachetti } from './brachetti';
import faireLaPlongueAudio from '../../../audios/faireLaPlonge.mp3';
import { faireLaPlonge } from './faireLaPlonge';
import { spectateurs } from './spectateurs';
import spectateursAudio from '../../../audios/spectateurs.mp3';
import { tourisme17 } from './tourisme17';
import tourisme17Audio from '../../../audios/tourisme17.mp3';
import { lespace } from './lespace';
import lespaceAudio from '../../../audios/espace.mp3';
export const oralIntermediate = [{
    title: "Le spectacle d'Arturo Brachetti",
    data: {
        audio: brachettiAudio,
        questions: Brachetti,
        credit: "https://audio-lingua.eu/spip.php?article4100&lang=fr",
        transcription: [
            `Mardi dernier, nous sommes allés en famille voir le spectacle d'Arturo Brachetti & friends, qui se déroulait au Palais-Royal à Paris, au théâtre du Palais-Royal, le titre exact était « Arturo Brachetti & friends, Comedy Majik Cho ». Donc c'est un spectacle qui vraiment est très exceptionnel, il n'y avait que quarante représentations dans ce théâtre qui est l'un des plus beaux de Paris, et qui était donc un spectacle à la fois de magie et d'humour, c'est ce qu'on appelle le Comedy Magic, c'est un nouveau genre qui vient des États-Unis, où on mélange effectivement différentes choses, à la fois des choses sur l'humour, et à la fois des choses sur la magie, sur le show.`,
            `Il y avait donc Arturo Brachetti en maître de cérémonie un peu déjanté, et il était entouré de sept illusionnistes talentueux qui sont connus, notamment à l'international, et il y avait dans ce spectacle, c'était un monde un petit peu merveilleux aux allures un peu de cabaret, festif, moderne. C'était assez émouvant, il y avait de la magie, il y avait aussi des choses qui rigolaient de la magie, donc c'était assez drôle.`,
            `Arturo Brachetti c'est on pourrait dire un artiste italien, et il est considéré comme le plus grand maître de la métamorphose au monde. Il est connu pour pouvoir changer de tenue très rapidement en très peu de secondes, c'est vraiment bluffant ! On le voit se retourner, il a déjà changé de tenue, enfin voilà, on ne sait pas en tout cas comment ils font et on veut garder le côté mystérieux, mais si vous avez l'occasion, voilà de le voir en spectacle ou de voir certaines de ses vidéos peut-être sur Internet, n'hésitez pas à aller voir, c'est quand même très intéressant et on garde la magie avec des yeux d'enfant. Arturo Brachetti.`
        ]
    }
},
{
    title: "Mes petits boulots d'été - Faire la plonge",
    data: {
        audio: faireLaPlongueAudio,
        questions: faireLaPlonge,
        credit: "https://audio-lingua.eu/spip.php?article2498&lang=fr",
        transcription: [
            `Lorsque j'étais étudiante, j'ai eu l'occasion de faire plein de petits jobs d'été. Ce sont ces petites activités qu'on fait pendant les vacances de manière à gagner un petit peu d'argent lorsque l'on est étudiant. `,
            `L'un d'entre eux consistait à être, à travailler dans un restaurant et à faire la plonge, c'est à dire en fait faire la vaisselle dans ce restaurant, donc cela consistait à récupérer la vaisselle qui avait été utilisée par les clients pendant le service du midi ou du soir, et à la laver ou à la mettre dans le lave-vaisselle, ressortir du lave-vaisselle. Cela exigeait bien sûr d'être prudent, d'être rapide, et surtout de ne pas être maladroit de manière à ne rien casser ! `,
            `Une deuxième partie de ce petit boulot d'été était également de préparer les cocktails pour les clients, donc il fallait bien sûr bien connaître la carte et connaître le nom des alcools, le nom des jus, voilà et pouvoir préparer cela de manière rapide pour que les clients soient servis rapidement. ,`,
            `Alors c'est un métier qui m'a, voilà qui m'a plu, ceci dit c'est quand même très difficile et les horaires sont extensibles, c'est à dire que on travaille, on commence très tôt et on finit très tard. Cela n'était pas très très bien payé car j'étais jeune mais cela m'a fait une expérience professionnelle. ,`
        ]
    }
},
{
    title: "L’envol du tourisme en 2017",
    data: {
        audio: tourisme17Audio,
        questions: tourisme17,
        creditText: "Extrait du Journal en français facile du 15/01/2018 Rédactrice : Fanny Kablan",
        credit: null,
        transcription: [
            `Extrait du Journal en français facile du 15 janvier 2018 `,
            `Zéphirin Kouadio : `,
            `Un autre chiffre mondial qui s'envole: celui du tourisme en 2017. Céline Pellarin : Il y a eu un nombre record de touristes en Europe depuis la crise économique de 2010. Et en tête des pays les plus visités au monde, en 2017, il y a l'Espagne donc. Mais également la France. La France a accueilli presque quatre-vingt-dix millions de personnes venues visiter ce territoire. Et certainement parmi ces touristes, et bien des auditeurs du Journal en français facile, venus pratiquer la langue de Molière en vacances ou pour leur travail.`,
        ]
    }
},
{
    title: "Destination : l’espace",
    data: {
        audio: lespaceAudio,
        questions: lespace,
        creditText: "Extrait du JFF 17/11/2016 Rédactrice : Fanny Kablan",
        credit: null,
        transcription: [
            `Extrait du Journal en français facile du 17 novembre 2016`,
            ` Sylvie Berruet : `,
            `Décollage prévu dans 20 minutes. Trois cosmonautes s’envolent cette nuit de Baïkonour au Kazakhstan pour la station spatiale internationale à 400 km au-dessus de la terre.`,
            `Adrien Delgrange : `,
            `Un Russe, un commandant de bord Oleg Novitski, une Américaine, Peggy Whitson, et un Français,Thomas Pesquet. Leur mission va durer 6 mois. Ils sont désormais tous installés dans la fusée à l’heure actuelle. Simon Rozé bonsoir.`,
            `Simon Rozé :`,
            `Bonsoir`,
            'Adrien Delgrange : ',
            `Vous êtes à quelques mètres du pas de tir de la fusée, alors, quelle est l'ambiance ?`,
            `Simon Rozé :`,
            `Quelques mètres, plutôt un kilomètre parce que vous l'imaginez, une fusée, ça fait des flammes quand ça décolle en l’occurrence la fusée Soyouz, elle fait 50 mètres de haut, elle a vingt moteurs à sa base, vingt moteurs qui peuvent dégager une puissance de vingt millions de chevaux. Alors, ici au site d'observation où je me trouve, là on attend, on est un peu transi de froid il fait -30° mais il y a beaucoup d'excitation`,
            ` Thomas Pesquet, le Français qui va décoller ce soir qui se trouve en ce moment même dans la fusée qui est en train de vérifier que tout se passe bien avec les commandeurs au sol. Il y a beaucoup de Français ici donc puisque Thomas Pesquet, ça faisait 8 ans que des Français n'étaient pas partis dans l'espace. Beaucoup d'attente donc pour cette mission de 6 mois à bord de la station spatiale internationale et désormais il n'y qu'un seul mot qu'on attend ici, tous, c'est пуск, пуск ça veut dire démarrage, décollage en russe. Un mot qui devrait être prononcé allez dans 19-18 minutes à présent ici au cosmodrome de Baïkonour au Kazakhstan.`,
            `Adrien Delgrange : `,
            `Simon Rozé sur la steppe kazakhe.`,
            `Retour sur la planète de ces 3 cosmonautes prévu en mai 2017, et nous vous retrouverons dans le prochain rendez-vous d’information sur RFI.`
        ]
    }
},
{
    title: "Défilé du 14-juillet : rencontre avec les spectateurs",
    data: {
        audio: spectateursAudio,
        questions: spectateurs,
        credit: null,
        creditText: "Extrait du Journal en français facile du 14 juillet 2021 Rédactrice : Fanny Kablan",
        transcription: [
            `Extrait du Journal en français facile du 14 juillet 2021`,
            'Clémentine Pawlotsky :',
            `RFI, il est 22h07 à Paris, c'est aujourd'hui la fête nationale en France.`,
            `Romain Auzouy : `,
            `La fête du 14-Juillet qui est marquée par des feux d'artifice notamment, partout dans le pays, le plus important prévu dans un peu moins d’une heure au pied de la tour Eiffel à Paris. Non loin de là, s'est tenu, ce matin, le défilé militaire du 14-Juillet, là aussi c'est une tradition française. L'événement avait dû être annulé l'an dernier en raison de la pandémie de Covid-19. Cette année, le nombre de spectateurs était réduit : 10 000 contre habituellement 25 000 mais le spectacle a bien pu avoir lieu sur les Champs-Élysées. Écoutez plutôt ce reportage d'Alice Rouja.`,
            `[On entend des avions de chasse qui défilent dans le ciel puis des applaudissements et des cris du public, et enfin la musique d'une fanfare.]`,
            `Alice Rouja :`,
            `Après une salutation aux troupes par le président de la République, le défilé est lancé par les avions de la Patrouille de France. Survolant les Champs-Élysées, ils laissent derrière eux des fumigènes bleu, blanc, rouge sous le regard ébahi de la petite Élisa.`,
            `« Ça c’était mon préféré, parce qu’il y avait des fumées. »`,
            `Accoudés aux barrières pour ne pas en perdre une miette, les enfants s'extasient devant les chars ou la garde montée. Chez les adultes, on préfère les sous-mariniers ou la Légion étrangère.`,
            `« Je n’ai pas vu ce que je préférais voir, c’est la Légion étrangère. Mais les pompiers sont impressionnants, ils sont beaux. Je pense que chaque section est belle dans sa tenue. Et voila, c’est ce qui fait la beauté du défilé. »`,
            `Dans la foule, femmes et enfants retiennent leur souffle quand la division d’un membre de la famille est représentée.`,
            `« Son papa est pompier lui aussi, donc c’est très émouvant et puis, on a perçu l’ambiance qui était vraiment magique finalement. »`,
            `Malgré les restrictions sanitaires et un accès au public limité, les spectateurs se sont régalés et au-dessus du masque, les yeux pétillent.`,
            `« On est fiers d’être français, c’est très émouvant quand on voit tous ces hommes qui servent la France, qui défendent la France, ça fait forcément quelque chose. »`,
            `Après plus d'un an de pandémie, c'est avec beaucoup d'émotion que les spectateurs ont retrouvé leur traditionnel 14-Juillet.`,
            `Romain Auzouy : `,
            `Voilà, reportage signé Alice Rouja sur les Champs-Élysées à Paris.`
        ]
    }
},

]

