import React from 'react'
import Calificar from '../../../Components/Calificar'

const Rubric = () => {
    return (
        <div style= {{marginTop: "2em"}}>
            <Calificar />
        </div>
    )
}

export default Rubric
