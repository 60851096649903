const charlesParle = [{
    questionText: "Charles vient du Québec, plus précisément d'Abitibi-Témiscamingue. Cette région se situe",
    answerOptions: [{
            answerText: "au nord du Québec",
            isCorrect: false
        },
        {
            answerText: "au nord-ouest du Québec",
            isCorrect: true
        },
        {
            answerText: "au sud-est du Québec",
            isCorrect: false
        },
    ]
},
{
    questionText: "Charles aime bien les sports d'hiver et il en pratique plusieurs. Quel sport ne mentionne-t-il pas ?",
    answerOptions: [{
            answerText: "Le snow",
            isCorrect: false
        },

        {
            answerText: "Le skidoo",
            isCorrect: false
        },
        {
            answerText: "Le ski de fond",
            isCorrect: false
        },
        {
            answerText: "Le bobsleigh",
            isCorrect: true
        },
        {
            answerText: "Le hockey",
            isCorrect: false
        },
    ]
},
{
    questionText: " Charles est en France au moment où il parle",
    answerOptions: [{
            answerText: "Vrai",
            isCorrect: true
        },
        {
            answerText: "Faux",
            isCorrect: false
        },
    ]
},
{
    questionText: "Il trouve que l'hiver en France",
    answerOptions: [{
            answerText: "est particulièrement intéressant",
            isCorrect: false
        },
        {
            answerText: "n'est pas particulièrement intéressant",
            isCorrect: true
        },
    ]
},
{
    questionText: "En France en hiver",
    answerOptions: [{
            answerText: "c'est humide, c'est froid et il y a beaucoup de neige",
            isCorrect: false
        },
        {
            answerText: "c'est humide, c'est froid mais il n'y a pas de neige",
            isCorrect: true
        },

    ]
},
{
    questionText: "Pour profiter des sports d'hiver en France, il est allé",
    answerOptions: [{
            answerText: "dans les Alpes",
            isCorrect: true
        },
        {
            answerText: "dans le Jura",
            isCorrect: false
        },

        {
            answerText: "dans les Pyrénées",
            isCorrect: false
        },
    ]
},
{
    questionText: "Il recommande à tout le monde",
    answerOptions: [{
            answerText: "de faire du ski au Québec",
            isCorrect: false
        },
        {
            answerText: "de faire du ski en France dans les Alpes",
            isCorrect: true
        },

        {
            answerText: "de faire du ski en France dans les Pyrénées",
            isCorrect: false
        },
    ]
},

]

export {
    charlesParle
};