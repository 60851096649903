import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function PublicRoute({ component: Component, ...rest }) {
  const auth = useSelector(state => state.auth)
  return (
    <Route {...rest}>
      {auth.uid ? (
        <Redirect to="/Material" />
      ) : (
        <Component />
      )}
    </Route>
  );
}
