import React, { useState } from 'react'
import { useEffect } from 'react';
import { useRef } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import { useForm } from '../../../../../hooks/useForm';

const GrillaCompetencia = ({ info = [], nota, setNota, changeStateItem }) => {
    const [controller, setController] = useState(null);
    const [last, setLast] = useState(null);
    const [nota1, setNota1] = useState(0);
    const [nota2, setNota2] = useState(0);
    const [nota3, setNota3] = useState(0);
    const handleInputchange = (e, indx) => {
        const { itemNumber, puntuacion, isActive } = info[indx].options.find((x) =>
            x.text.toString() === e.target.value
        )
        console.log({itemNumber, puntuacion, isActive})
        handleOperacionMatematica(itemNumber, puntuacion, isActive);
    }

    useEffect(()=>{
        console.log(nota1);
        setNota(nota + nota1)
    }, [nota1])
    useEffect(()=>{
        console.log(nota1);
        setNota(nota + nota2)
    }, [nota2])
    useEffect(()=>{
        console.log(nota1);
        setNota(nota + nota3)
    }, [nota3])

    const handleOperacionMatematica = async(itemNumber, puntuacion, isActive) => {
        console.log({itemNumber, puntuacion})
        if (itemNumber === 1) {
            console.log("aqui me 1")

            await setNota(nota - nota1)
           await setNota1(puntuacion);
        }
        if (itemNumber === 2) {
            console.log("aqui me 2")
            await setNota(nota - nota2)
           await setNota2(puntuacion);
        }
        if (itemNumber === 3) {
            console.log("aqui me 3")
            await setNota(nota - nota3)
            await setNota3(puntuacion);
        }
        console.log({nota1, nota2, nota3})
        setNota(nota1 + nota2 + nota3)
        changeStateItem(itemNumber, puntuacion);
    };


    return (
        <Container className='grid testListFather' fluid="md">

            {info.map((x, i) => {
                console.log(x);
                return <>
                    <Row className='justify-content-lg-center align-items-center' key={x.categorie.substring(0, 3)}>
                        <Col xs lg="8">
                            <div className='blockTB'>
                                {x.title && <h5>{x.title}</h5>}
                                <p>{x.categorie}</p>
                            </div>
                        </Col>
                        <Col xs lg="">
                            <div className='blockTB'>
                                <Form.Group className="mb-3" >
                                    <Form.Select onChange={(e) => handleInputchange(e, i)} name="inputSelect">
                                        {x?.options?.map((x, i) => <option key={i}>{x.text}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                </>
            })}
        </Container>
    )
}

export default GrillaCompetencia