import React from 'react'
import { VideosRender } from '../../Components/VideosRender'
export const videosScreen = () => {
    return (
        <>
        <div className='' style={{marginTop: "100px"}}></div>
        <VideosRender />

        </>
    )
}
