import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleGetLevelAndRol } from "../services/index.services";
import { login, setCurrentLanguagec, setLanguageAndStatus } from "../actions/auth";

const useCheckStatus = () => {
  const { auth } = useSelector((x) => x);
  const dispatch = useDispatch();
  const validateStatus = async (calledFrom) => {
    console.log(calledFrom);
    // return;
    const result = await handleGetLevelAndRol(auth.uid);
    let language;

    if (result.language !== undefined) {
      language =
        result.language[0].length > 1 && result.language !== undefined
          ? result.language[0]
          : result.language;
      const object = {
        currentLanguage: language,
        currentLevel: result.level,
        rol: result.rol
      }
      dispatch(
        setCurrentLanguagec(object)
      );
    }
    if (result.languageInfo !== undefined) {
      language = result.languageInfo[0];
      if (result.languageInfo.length > 1 && (auth.currentLanguage !== undefined || auth.currentLanguage !== null)) {
        language = result?.languageInfo.filter((x) => x.language === auth.currentLanguage)[0];
      }
      if (language === undefined) {
        language = result.languageInfo[0];
      }
      const thing = result;
      const thing2 = result.rol;
      const object = {
        currentLanguage: language?.language,
        currentLevel: language?.level,
        rol: language?.rol ?? result.rol,
      }
      console.log(object)
      dispatch(
        setCurrentLanguagec(object)
      );
    }
    console.log(language);

    console.log(result);
    return result;
  };
  return { validateStatus, auth };
};

export default useCheckStatus;
