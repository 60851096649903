import React, { useEffect, useState } from 'react'
import useCheckStatus from '../../hooks/useCheckStatus';
import { useDispatch } from 'react-redux';
import { login, setLanguageAndStatus, startLogout } from '../../actions/auth';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const Buttonss = ({ children: Children, onClicks = () => { }, isLink = false, url = "", ...props }) => {
    const { validateStatus } = useCheckStatus();
    const { auth } = useSelector((x) => x);
    const [letDo, setLetDo] = useState(true);
    const dispatch = useDispatch();

    return (
        <button {...props} onClick={async () => {
            //TODO consultar estado
            const result = await validateStatus('Buttonss');
            if (result.status !== "Inactivo") {
                setLetDo(true);
                // if (JSON.stringify(result.languageInfo) !== JSON.stringify(auth.languageInfo)) {
                    dispatch(login(result))
                // }
                onClicks();
                if (isLink) {
                    const a = document.createElement("a");
                    a.href = url;
                    a.target = "_blank";
                    a.rel = "noopener noreferrel";
                    a.click();
                }
            } else {
                setLetDo(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    text: 'Tu usuario ha sido bloqueado, contacta con administración para obtener mas información',
                }).then(() => dispatch(startLogout()))
            }
        }}>
            {letDo && Children}
        </button>
    )
}

export default Buttonss
