import { nanoid } from 'nanoid'
import React from 'react'
import { Table } from 'react-bootstrap'

const TableComponent = ({ setNota, nota, changeStateItem, calificarInfo }) => {

    const handleOperacionMatematica = (itemNumber, puntuacion, isActive) => {
        let itemOne;
        let itemTwo;
        let itemThree;
        let itemFour;

        if (itemNumber === 1) {
            itemOne = puntuacion;
            if (isActive === false) {
                if (nota !== 0) {
                    setNota(nota + itemOne);
                } else if (itemOne !== puntuacion) {
                    setNota(
                        nota + puntuacion
                    )
                }
                else {
                    setNota(
                        itemOne
                    );
                }
            } else {

                setNota(
                    nota - itemOne
                )
            }
        }
        if (itemNumber === 2) {
            itemTwo = puntuacion
            if (!isActive) {
                if (nota !== 0) {
                    setNota(nota + itemTwo);
                } else {
                    setNota(
                        nota + itemTwo
                    );
                }
            } else {
                setNota(
                    nota - itemTwo
                )
            }

        } if (itemNumber === 3) {
            itemThree = puntuacion
            if (!isActive) {
                if (nota !== 0) {
                    setNota(nota + itemThree);
                } else {
                    setNota(
                        nota + itemThree
                    );
                }
            } else {
                setNota(
                    nota - itemThree
                )
            }
        }
        if (itemNumber === 4) {
            itemFour = puntuacion
            if (!isActive) {
                if (nota !== 0) {
                    setNota(nota + itemFour);
                } else {
                    setNota(
                        nota + itemFour
                    );
                }
            } else {
                setNota(
                    nota - itemFour
                )
            }
        }
        changeStateItem(itemNumber, puntuacion);
    }
    return (
        <Table bordered style={{ maxWidth: "700px", margin: "0 auto", paddingLeft: "20px" }} size="sm">
            <thead>
                <tr>
                    {calificarInfo.headers.map((head) => <th key={nanoid()}>{head}</th>)}
                </tr>
            </thead>
            <tbody>
                {calificarInfo.rows.map(row => {
                    return <tr key={nanoid()}>
                        <td key={nanoid()}>{row.categorie}</td>
                        {row.options.map(option => {
                            return (

                                <td className="font-small " key={nanoid()} >
                                    <button
                                        disabled={option.disabled}
                                        className={option.isActive ? "pointer w-100 h-100 button_noBorder itemSelected" : "pointer w-100 h-100 button_noBorder"}
                                        onClick={() => handleOperacionMatematica(option.itemNumber, option.puntuacion, option.isActive)}>
                                        {option.text}
                                    </button>
                                </td>
                            )
                        })}
                    </tr>
                })}
            </tbody>
        </Table>
    )
}

export default TableComponent
