import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { dataHome, secondData } from "../utils/data";
import { Carrusl } from "../Carousel/carousel";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoDiamond } from "react-icons/io5";
import { SiGoogleclassroom } from "react-icons/si";
import { useHistory } from 'react-router-dom'
import "./animation.css";
import { CourseLvl } from "../UI/CourseLvl";
import { useChangeStatusLvl } from "../../hooks/useChangeStatusLvl";
import { SideToSide } from "../UI/SideToSide";
import SVG from "./Course.svg";
import banner from "./learnBanner.png";
import Testimonios from "../Testimonios/Testimonios";
import { VideosRender } from "../VideosRender";
import { Card } from "react-bootstrap";
import { act } from "react-dom/test-utils";
import { CardWithImage } from "../cardWithImage";
import WebToLeadForm from "../webToLeadForm";

export default function Main() {
  const { changeStatusLvl, infoCourse, activeLvl } = useChangeStatusLvl();
  const navigate = useHistory();
  return (
    <>
      <main className="mainClass">
        <section className=" mainClass__header">
          <div className="col header_text ">
            <h1 className="mainFont tracking-in-contract">
              Aprende Francés fácil y rápido sin salir de casa
            </h1>
            <div className="header_text__icons">
              <ul className="d-flex justify-content-evenly align-items-start">
                <li className="icons_font">
                  <FaChalkboardTeacher />
                  <br />
                  <h3>Profesores Certificados</h3>
                  <p>Con amplia experiencia en la enseñanza de idiomas.</p>
                </li>
                <li>
                  <IoDiamond />
                  <br />
                  <h3>Metodología 100% dinámica</h3>
                  <p>Comenzarás a hablar Francés desde tu primera clase.</p>
                </li>
                <li>
                  <SiGoogleclassroom />
                  <br />
                  <h3>Clases en vivo</h3>
                  <p>
                    Conéctate a nuestras clases desde tu computador, teléfono o
                    tablet.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <SideToSide
          embed="https://www.youtube.com/embed/vWmddsRephY"
          dataHome={dataHome}
          leftToRight={true}
          Mark={true}
        />
        <SideToSide
          embed={SVG}
          dataHome={secondData}
          leftToRight={false}
          Mark={false}
        />
        <section className="w-100 d-flex justify-content-center align-items-center flex-column">
          <h2 className="titleFont">Beneficios</h2>
          <Testimonios embed="https://www.youtube.com/embed/YpxFW7QldcY" />
          <Carrusl />
        </section>

        <h2 className="titleFont text-center">
          ¿Qué aprenderás en cada nivel?
        </h2>
        <section className="d-flex justify-content-evenly w-100 h-100  courseInfo-container">
          {infoCourse.map(item => (<><CourseLvl lvl={item} changeStatusLvl={changeStatusLvl} /> <div></div></>))}
        </section>
        <Card className="mainClass__cardI" >
          <Card.Body>
            {infoCourse.map((element, indexOne) => {
              let info = infoCourse[0];
              let equals = false;
              Object.keys(activeLvl).forEach((item, index) => {
                if (indexOne === index && activeLvl[item]) {
                  equals = true;
                  info = element;
                  return;
                }
              })
              return equals ? <p>{info.content}</p> : null;
            })}

          </Card.Body>
        </Card>
        <h2 className="fw-bold fs-1 text-uppercase text-center">Cápsula de gramática</h2>
        <div style={{width: '80vw'}}>
        <CardWithImage image={banner} accion={()=> navigate.push('/videos')} />
        </div>
        <br />
        <br />
        <h2 className="fw-bold fs-1 text-uppercase text-center">Testimonios</h2>
        <Testimonios embed="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FGoGo.Academysv%2Fvideos%2F202766328670012%2F&show_text=false&width=560&t=0" />
        {/* <WebToLeadForm /> */}
        
      </main>
    </>
  );
}
