import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SVG from './knowledge-animate.svg';
import { Badge, Col, Row } from 'react-bootstrap';
import Buttonss from '../UI/Buttonss';
import { StudentStagePR } from '../../Screens/Material/components/studentStagePR';



const EstudianteHeaderPort = ({
    level,
    url,
}) => {
    const auth = useSelector(state => state.auth);
    return (
        <div className="main">
            <section className="section">

                <div className="section__container--img">
                    <img src={SVG} className="container__img" alt="Libro color naranja flotando con un circulo transparente el la parte de atras" />

                </div>
                {
                    auth.rol !== "profesor" &&
                    <div className='d-flex  flex-column justify-content-center align-items-center'>
                        <h3 className='text-center'>
                        Bem-vindo {auth.name}!
                        </h3>
                        <h3 className='text-center'>
                        Seu nível: <Badge bg={auth.level === "A1" ? "primary" : auth.level === "A2" ? "warning" : auth.level === "B1" || auth.level === "B2" ? "dark" : "danger"}>{auth.level === "A1" ? "Basico" : auth.level === "A2" ? "Intermediário" : auth.level === "B1" || auth.level === "B2" ? "Avançado" : "Clube "}</Badge>
                        </h3>
                    </div>
                }
                <hr />
                {
                    auth?.rol === "club" ?
                        <div className="section__container--buttons">
                            <div className="boton_up">
                                <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_CLUB_EN}>
                                    Conversation club material
                                </Buttonss>
                            </div>
                        </div>
                        :
                        <StudentStagePR level={level} url={url} />
                }
            </section>
        </div>)
}

export default EstudianteHeaderPort;