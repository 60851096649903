const brunoQuiz = [{
        questionText: "La maison de Bruno a",
        answerOptions: [{
                answerText: "3 étages",
                isCorrect: true
            },
            {
                answerText: "2 étages",
                isCorrect: false
            },
        ]
    },
    {
        questionText: "La cuisine est",
        answerOptions: [{
                answerText: "dans le grenier",
                isCorrect: false
            },

            {
                answerText: "au rez-de-chaussée",
                isCorrect: true
            },
            {
                answerText: "au 1er étage",
                isCorrect: false
            },
        ]
    },
    {
        questionText: "Au 1er étage, il y a",
        answerOptions: [{
                answerText: "une salle de jeux, un bureau, 2 chambres",
                isCorrect: false
            },
            {
                answerText: "une salle de bains, un bureau, 2 chambres",
                isCorrect: true
            },

        ]
    },
    {
        questionText: "Bruno a",
        answerOptions: [{
                answerText: "un enfant",
                isCorrect: false
            },
            {
                answerText: "plusieurs enfants",
                isCorrect: true
            },

        ]
    },
    {
        questionText: "Il habite",
        answerOptions: [{
                answerText: "près de Lyon",
                isCorrect: false
            },
            {
                answerText: "près de Marseille",
                isCorrect: false
            },

            {
                answerText: "près de Paris",
                isCorrect: true
            },
        ]
    },

]

export {
    brunoQuiz
};