const laMinuteActu = [{
    questionText: "En France, les enseignants étaient « en grève ». Ça signifie qu’ils…",
    answerOptions: [{
            answerText: "n’ont pas travaillé",
            isCorrect: true
        },
        {
            answerText: "étaient malades.",
            isCorrect: false
        },
    ]
},
{
    questionText: "Les enseignants sont contre…",
    answerOptions: [{
            answerText: "l’enseignement à distance.",
            isCorrect: false
        },

        {
            answerText: "les règles anti-Covid dans les écoles",
            isCorrect: true
        },
    ]
},
{
    questionText: "À Marseille, il y a eu...",
    answerOptions: [{
            answerText: "2 200 manifestants.",
            isCorrect: true
        },
        {
            answerText: "22 000 manifestants.",
            isCorrect: false
        },
        {
            answerText: "220 000 manifestants",
            isCorrect: false
        },

    ]
},

//pending cause the format
//TODO: create the component to show this question with checkboxes.
// {
//     questionText: "Réécoutez l’extrait. Quels mots entendez-vous ?",
//     answerOptions: [{
//             answerText: "un enfant",
//             isCorrect: false
//         },
//         {
//             answerText: "plusieurs enfants",
//             isCorrect: true
//         },

//     ]
// },


]

export {
laMinuteActu
};