import fredericParleAudio from '../../../audios/fredericParle.mp3';
import charlesParleAudio from '../../../audios/charlesParle.mp3';
import bitcoinAudio from '../../../audios/bitcoin.mp3';

import { charlesParle } from './charlesParle';
import { fredericParle } from './fredericParle';
import leBonheurAudio from '../../../audios/leBonheur.mp3';
import { leBonheur } from './leBonheur';
import { Bitcoin } from './bitcon';
export const oralAdvanced = [{
    title: "Frédéric parle de son Nouvel An",
    data: {
        audio: fredericParleAudio,
        questions: fredericParle,
        credit: "https://audio-lingua.eu/spip.php?article1266&lang=fr",
        creditText: "Source audio : Audio-Lingua - Frédéric (Québec) - Mes résolutions",
        transcription: [
            `Bonjour, bon, moi je m'appelle Frédéric et je viens de Montréal, je suis actuellement à Paris pour fêter le Nouvel An, et puis... ben en fait l'an prochain qu'est-ce que j'aimerais faire ? Mes résolutions seraient de perdre du poids, faire de l'exercice, bon sûrement manger mieux, mieux manger, et puis ben c'est un peu pour ça que je suis ici dans le supermarché parce qu'on va se faire ce soir un super bœuf Wellington et une bouteille de Champagne et puis on va avoir un très très bon repas.`
        ]
    }
},
{
    title: "Charles parle des sports d'hiver",
    data: {
        audio: charlesParleAudio,
        questions: charlesParle,
        credit: "https://audio-lingua.eu/spip.php?article622&lang=fr",
        creditText: "Source audio : Audio-Lingua - Charles (Québec) - Charles du Québec parle des sports d'hiver",
        transcription: [
            `Bonjour, je m'appelle Charles Bérubé, je viens du Québec, du nord-ouest du Québec en Abitibi-Témiscamingue, j'aime bien les sports d'hiver, l'hiver je pratique, je fais du snow, je fais du skidoo, du ski de fond, beaucoup de sports d'hiver, je joue au hockey avec mes chums à l'aréna.`,
            `Donc j'adore l'hiver et ici en France ce n'est pas vraiment un hiver particulièrement intéressant. C'est humide, c'est froid mais il n'y a pas de neige, on ne peut pas vraiment profiter des sports d'hiver, sinon il faut aller dans les Alpes et j'en ai profité quand même il y a une semaine pour aller faire du ski dans les Alpes, c'était vraiment une expérience extraordinaire, je recommande à tout le monde.`
        ]
    }
},
{
    title: `Le Bonheur au travail`,
    data: {
        audio: leBonheurAudio,
        questions: leBonheur,
        credit: null,
        creditText: null,
        transcription: null
    }
},
{
    title: `Salvador : « Bitcoin City » `,
    data: {
        audio: bitcoinAudio,
        questions: Bitcoin,
        credit: null,
        creditText: `Extrait du Journal en français facile du 21/11/2021 Rédactrice : Katia Brandel`,
        transcription: [
            `Extrait du Journal en français facile du 21 novembre 2021`,
            `Sébastien Duhamel : 
            Économie à présent, Raphaël. 
            `,
            `Le Salvador a fait du bitcoin une monnaie officielle, il y a deux mois.`,
            `Et une nouvelle étape hier soir, avec le lancement d'un projet de ville entièrement basée sur le bitcoin, la plus puissante des cryptomonnaies.`,
            `Raphaël Delvolve : 
            Oui, le président du Salavador, Nayib Bukele, l'a annoncé. Cette future ville n'aura pas de revenu, pas de taxes sur la propriété, sur le capital ou encore les charges sociales. 
            `,
            `Une sorte de zone franche, la première du genre dans le monde puisqu’elle aura pour seule monnaie donc le bitcoin. `,
            `Anne Verdaguer, à quoi ça sert cette ville Bitcoin : « Bitcoin City » ?`,
            `Anne Verdaguer :
            L'idée est de faire du Salvador « le centre financier du monde » ou « le Singapour de l'Amérique latine », c'est le directeur stratégie de Block Stream, qui le dit, une société canadienne qui a orchestré avec les autorités salvadoriennes ce projet un peu fou de « ville bitcoin ». 
            `,
            `La comparant à Alexandrie, le président salvadorien Nayib Bukele a décrit « Bitcoin City » comme une ville circulaire, avec un aéroport, des zones résidentielles et commerciales, et une place centrale conçue pour ressembler à un symbole bitcoin vu du ciel.`,
            `La ville sera construite dans la région de La Union non loin d’un volcan, le Salvador mise sur l’énergie géothermique. `,
            `Aucune taxe n'y sera prélevée sauf la TVA - la taxe sur la valeur ajoutée - qui financera des obligations pour un montant d’un milliard de dollars qui serviront en partie à construire la ville, l'autre moitié servira à acheter encore plus de bitcoins. `,
            `Dans un pays où une partie de la population a manifesté pour dire son rejet de cette monnaie très volatile, les projets se multiplient : 20 écoles pourraient être construites avec les bénéfices réalisés grâce au bitcoin. `,
            `La cryptomonnaie a atteint en octobre son plus haut historique à 66 000 dollars pour 1 bitcoin. `
        ]
    }
},

]