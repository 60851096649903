import React from 'react'
import { Link } from 'react-router-dom';
import { autoScroll } from '../../utils/Autoscroll';
import SVG from './knowledge-animate.svg';
import Buttonss from '../UI/Buttonss';
import { useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';


const TeacherHeaderPort = ({
    level,
    url,
}) => {
    const auth = useSelector((x) => x.auth);
    return (
        <div className="main">
            <section className="section">
                <div className="section__container--img">
                    <img src={SVG} className="container__img" alt="Libro color naranja flotando con un circulo transparente el la parte de atras" />
                </div>
                <div className='d-flex  flex-column justify-content-center align-items-center'>
                    <h3 className='text-center'>
                    Bem-vindo {auth.name}!
                        <br />
                        <Badge bg="dark">Professor</Badge>

                    </h3>
                </div>
                <div className="section__container--buttons">
                    <div>
                        <h5>
                            De momento estamos trabajando para que puedas sacar el máximo provecho a la plataforma
                        </h5>
                    </div>
                </div>
            </section >
        </div >)
}

export default TeacherHeaderPort;