import React from "react";
export const FloatingMedia = ({currentIcon, url, url2, currentIcon2}) => {
    return (
        <>
        <div className="floating-media  d-flex align-items-center justify-content-between rounded-circle ">
            <div className = "rounded-circle">
                <a href={url} target="_blank" rel="noopener noreferrer">{currentIcon}</a>
            </div>
        </div>
        <div className="floating-media2  d-flex align-items-center justify-content-between rounded-circle ">
            <div className = "rounded-circle">
                <a href={url2} target="_blank" rel="noopener noreferrer">{currentIcon2}</a>
            </div>
        </div>
        </>
    );
};
