import { Form } from 'formik'
import React from 'react'

const RecoverFormComponent = () => {
    return (

        <div>
                </div>
    )
}

export default RecoverFormComponent
