import React, { useEffect } from 'react'
import { autoScroll } from '../utils/Autoscroll';
import './terms.css';
export const TermsAndContitions = () => {
    useEffect(() => {
        autoScroll(0, 0);
        return () => {
        }
    }, [])
    return (
        <section className="principalito">
            <div className="termino">
                <b className="Terminosh1 encabezado__h1"><h1>Términos de Servicio</h1></b>
                <h3 className='termsh3'>Go Go Academy</h3>
                <h4>Fecha de Entrada en vigor: 1 de enero de 2020</h4>
                <p className="letrerote">
                    Bienvenidos a Go Go Academy. Nos complace ofrecerle acceso al Servicio (como se define más
                    abajo), sujeto a estos términos y condiciones (los “Términos de Servicio”) y a las normas y
                    políticas de la academia (las “Normas y Políticas “). Al acceder y utilizar el Servicio, usted
                    expresa su consentimiento, acuerdo y entendimiento de los Términos de Servicio, Normas y
                    Políticas.
                </p>
                <h4 className='termsh4'>Términos de Servicio </h4>
                <p className="letrerote">
                    1. Go Go Academy ofrece cursos de francés completamente en línea y en tiempo real. Le
                    ofrecemos la posibilidad de aprender francés utilizando las plataformas: Google Meet, Google
                    Classroom
                    <br /><br />
                    <b>2.	Permiso para utilizar el Servicio; reserva de derechos:</b> Al aceptar estos Términos de
                    Servicio, se le concede a usted un derecho limitado, no exclusivo e intransferible para entrar,
                    acceder y utilizar el Servicio exclusivamente para uso personal y no comercial.
                    <br /><br />
                    <b>3. Promociones: </b>Go Go Academy, de vez en cuando, coloca anuncios publicitarios o presenta
                    ofertas promocionales a través de sus redes sociales. Su participación en cualquier promoción
                    u oferta está sujeta a las normas aplicables relacionadas con la promoción.

                    <br /><br />
                    <b>4. Grupos de clase: </b> Con el propósito de brindar excelente rendimiento académico, los grupos
                    se mantienen en un rango de 5 a 15 estudiantes. Si por diferentes motivos el grupo de clases
                    es menor a 5 estudiantes, la administración de Go Go Academy ofrecerá un cambio de grupo.
                    Sin embargo, si el estudiante no puede o decide no asistir al nuevo grupo, se le hará un
                    reembolso parcial de las horas restantes de su mensualidad (16 horas).

                </p>
                <h4 className='termsh4'>Normas y políticas</h4>
                <p className="letrerote">
                    <b>1. Requsito de edad: </b>
                    Si es menor de 18 años, su padre, madre o tutor legal debe aceptar
                    estos Términos de Servicio y registrarse para el Servicio en nombre de usted, los cuales se
                    presentarán a la hora que realice el pago para luego inscribirse al curso de francés.
                    <br /><br />
                    <b>2. Conducta prohibida: </b>
                    Usted no utilizará el Servicio para transmitir, mostrar, ejecutar o de
                    algún modo poner a disposición mensajes, contenidos o materiales que sean ilegales,
                    obscenos, amenazantes, masivos no solicitados o “spam”, difamatorios e invasores de la
                    privacidad.                </p>

                <h4 className='termsh4'>Cargos y facturación</h4>
                <p className="letrerote">
                    <b>1. Acuerdo de pago: </b>
                    Todos los usuarios del Servicio deben aceptar, en primer lugar, estos
                    Términos de Servicio.
                    <br /><br />
                    <b>2. Ciclo de facturación:</b> La mensualidad es calculada con base al número de horas de
                    clases agendadas. Es decir, el estudiante debe realizar sus pagos cada 16 horas de clases
                    agendadas (cada 4 semanas).
                    <br /><br />
                    <b>3. Política de reembolso:</b> -Luego de que realice el pago de mensualidad y asista al
                    primer día del curso, el pago o cargo realizado no es reembolsable. Si por cualquier
                    motivo, el estudiante no asiste a las clases agendadas en el horario elegido, las horas
                    serán igualmente descontadas del paquete mensual (16 horas). <b>La academia no se
                        compromete a reponer clases por ausencias de estudiantes.
                    </b>  El reembolso se realizará
                    completo o parcial únicamente en el caso: Cierre de grupos debido a pequeña cantidad de
                    estudiantes.

                    <br /><br />
                    <b>3. Terminación:</b> Si el estudiante por diferentes razones decide ya no seguir con el curso,
                    él puede optar por la opción de completar las clases de la mensualidad realizada, No
                    realizar el siguiente pago y notificar al docente encargado. De lo contrario, si se retira a
                    mediados del mes, los días restantes no son reembolsables.

                    <br /><br />
                    <b>3. Impuestos:</b> La academia cuenta con diferentes métodos de pagos como transferencias bancarias: Banco Agrícola, Banco Cuscatlán y Banco de América Central, los cuales no están sujetos a impuestos o taxes. Sin embargo, si el estudiante prefiere realizar el pago por medio de pagos en línea con tarjeta de crédito vía Wompi o Paypal, se deberá pagar las comisiones por utilizar el servicio.
                </p>
            </div>
        </section>
    )
}
