import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import TeacherHeader from "../Components/TeacherLevel/TeacherLevel";
import ControlScreen from "../Screens/Material/teacherScreens/ControlScreen";
import Rubric from "../Screens/Teachers/teachersScreens/Rubric";
import RubricAdvace from "../Screens/Teachers/teachersScreens/rubricAdvance/RubricAdvace";
import OnlyTeachers from "./onlyTeachers";
import { login, setLanguageAndStatus, startLogout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import useCheckStatus from "../hooks/useCheckStatus";
import Swal from "sweetalert2";
import TeacherHeaderEN from "../Components/TeacherLevel/TeacherLevelEN";
import TeacherHeaderPort from "../Components/TeacherLevel/TeacherHeaderPort";
import RubricEnA1 from "../Screens/Teachers/teachersScreens/english_rubrics/Rubric";

const OnlyTeachersRoute = ({ path, url }) => {
  const auth = useSelector((state) => state.auth);
  const { validateStatus } = useCheckStatus();
  const dispatch = useDispatch();
  useEffect(() => {
    const validate = async () => {
      const result = await validateStatus('teachersRoute');
      if (result.status !== "Inactivo") {
        // if (JSON.stringify(result.languageInfo) !== JSON.stringify(auth.languageInfo)) {
          dispatch(login(result))
        // }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Tu usuario ha sido bloqueado, contacta con administración para obtener mas información",
        }).then(() => dispatch(startLogout()));
      }
    };
    validate();
  }, []);
  return (
    <div>
      {auth.currentLanguage === "Francés" && <TeacherHeader url={url} />}
      {auth.currentLanguage === "Inglés" && <TeacherHeaderEN url={url} />}
      {auth.currentLanguage === "Portugués" && <TeacherHeaderPort url={url} />}

      <hr />
      <Switch>
        <OnlyTeachers path={`${path}/rubrica`} component={Rubric} />
        <OnlyTeachers
          path={`${path}/rubrica-advance`}
          component={RubricAdvace}
        />
        <OnlyTeachers
          path={`${path}/rubrica-advanced`}
          component={RubricEnA1}
        />
        <OnlyTeachers path={`${path}/control`} component={ControlScreen} />
      </Switch>
    </div>
  );
};

export default OnlyTeachersRoute;
