import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import Calificar from "../Components/Calificar";
import EstudiantesHeader from "../Components/EstudiantesLevel/EstudiantesLevel";
import Clases from "../Screens/Material/studentsScreens/Clases";
import Comprension from "../Screens/Material/studentsScreens/comprension";
import Gramatica from "../Screens/Material/studentsScreens/Gramatica";
import { Lectura } from "../Screens/Material/studentsScreens/Lectura";
import Pronunciacion from "../Screens/Material/studentsScreens/pronunciacion";
import TestLevel from "../Screens/Material/studentsScreens/TestLevel";
import OnlyTeachersRoute from "./onlyTeachers";
import useCheckStatus from "../hooks/useCheckStatus";
import { login, setCurrentLanguagec, setLanguageAndStatus, startLogout } from "../actions/auth";
import Swal from "sweetalert2";
import EstudiantesHeaderEN from "../Components/EstudiantesLevel/EstudianteLevelEN";
import ClasesEN from "../Screens/Material/studentsScreens/EN/course";
import EstudianteHeaderPort from "../Components/EstudiantesLevel/EstudianteLevelPort";
import ClasesPR from "../Screens/Material/studentsScreens/PR/course";
import { useState } from "react";

const MaterialRoutes = ({ path, url }) => {
  const auth = useSelector((state) => state.auth);
  const { validateStatus } = useCheckStatus();
  const dispatch = useDispatch();
  const navigate = useHistory();

  const [languageC, setLenguageC] = useState(auth.currentLanguage);
  useEffect(() => {
    const validate = async () => {
      const result = await validateStatus('materialRoutes');
      if (result.status !== "Inactivo") {
        if (JSON.stringify(result.languageInfo) !== JSON.stringify(auth.languageInfo)) {
          dispatch(setLanguageAndStatus({ languageInfo: result.languageInfo, status: result.status }))
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Tu usuario ha sido bloqueado, contacta con administración para obtener mas información",
        }).then(() => dispatch(startLogout()));
      }
    };
    validate();
  }, []);

  useEffect(() => {
    if (auth.languageInfo === undefined || (languageC === null || languageC === undefined)) return;
    dispatch(
      setCurrentLanguagec({
        currentLanguage: languageC,
        currentLevel: auth?.languageInfo.filter((x) => x.language === languageC)[0]?.level ?? auth.level,
        rol: auth?.languageInfo.filter((x) => x.language === languageC)[0]?.rol ?? auth.rol,
      })
    );
    navigate.push("/Material");
  }, [ dispatch, languageC, navigate]);

  useEffect(() => {
    setLenguageC(auth.currentLanguage)
  }, [
    auth.currentLanguage
  ])
  return (
    <>
      {((auth.language !== undefined && auth?.language[0].length > 1) ||
        (auth.languageInfo !== undefined && auth.languageInfo.length > 1)) && (
          <div className="lang_selector">
            {" "}
            <label htmlFor="language-select">Selecciona el curso: </label>
            <select
              id="language-select"
              value={languageC}
              onChange={(e) => {

                setLenguageC(e.target.value)
              }}
            >
              {auth.languageInfo !== undefined &&
                auth.languageInfo.map((x) => (
                  <option value={`${x.language}`}>{x.language}</option>
                ))}
              {auth.languageInfo === undefined &&
                auth.language.map((x) => <option value={`${x}`}>{x}</option>)}
            </select>
          </div>
        )}
      {auth.currentLanguage === "Francés" && (
        <EstudiantesHeader level="Básico" url={url} />
      )}
      {auth.currentLanguage === "Inglés" && (
        <EstudiantesHeaderEN level="Básico" url={url} />
      )}
      {auth.currentLanguage === "Portugués" && (
        <EstudianteHeaderPort level="Básico" url={url} />
      )}

      <hr />
      {auth.rol !== "club" ? (
        <Switch>
          {auth.currentLanguage === "Francés" && (
            <>
              <Route path={`${path}/lectura`} component={Lectura} />
              <Route path={`${path}/gramatica`} component={Gramatica} />
              <Route path={`${path}/pronunciacion`} component={Pronunciacion} />
              <Route
                path={`${path}/comprension-oral`}
                component={Comprension}
              />
              <Route path={`${path}/clases`} component={Clases} />
              <Route path={`${path}/test-nivel`} component={TestLevel} />
            </>
          )}
          <Route path={`${path}/course`} component={ClasesEN} />

          {/* {auth.language === "Portugés" && (
            
            )} */}
          <Route path={`${path}/clasesPR`} component={ClasesPR} />
          <OnlyTeachersRoute path={`${path}/calificar`} component={Calificar} />
        </Switch>
      ) : (
        <Switch>
          {auth.currentLanguage === "Francés" && (
            <>
              <Route path={`${path}/lectura`} component={Lectura} />
              <Route path={`${path}/gramatica`} component={Gramatica} />
              <Route path={`${path}/pronunciacion`} component={Pronunciacion} />
              <Route
                path={`${path}/comprension-oral`}
                component={Comprension}
              />
              <Route path={`${path}/clases`} component={Clases} />
              <Route path={`${path}/test-nivel`} component={TestLevel} />
            </>
          )}
          <Route path={`${path}/course`} component={ClasesEN} />

          {auth.currentLanguage === "Portugés" && (
            <Route path={`${path}/clasesPR`} component={ClasesPR} />
          )}
          <OnlyTeachersRoute path={`${path}/calificar`} component={Calificar} />
        </Switch>
      )}
    </>
  );
};

export default MaterialRoutes;
