import React from 'react'
import { Accordion } from "react-bootstrap";
import { nanoid } from 'nanoid';
import Buttonss from './UI/Buttonss';

const BookComponent = ({ bookList, title, eventKey }) => {
    return (
        <Accordion.Item eventKey={eventKey}>
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>
                <div className='row'>
                    {bookList && bookList.map((book) => {
                        return (<div className="col-sm" key={nanoid()}>
                            <Buttonss className="button-material"><a href={book.url} target="_blank" rel="noopener noreferrer" >{book.title}</a></Buttonss>
                        </div>)
                    })}
                </div>
            </Accordion.Body>
        </Accordion.Item>

    )
}

export default BookComponent;
