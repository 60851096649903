import React, { useEffect } from 'react';
import ClasesComponent from '../../../Components/ClasesComponent';
import { autoScroll } from '../../../utils/Autoscroll';

const Clases = () => {
    useEffect(() => {
        autoScroll(0,700)
        return () => {
            
        }
    }, [])

    return (
        <div className="container d-flex flex-column justify-content-center " id='materiel'>
            <h2 className='titleFont' >Matériel de cours</h2>
            <hr />
            <ClasesComponent />
        </div>
    );
};

export default Clases;
