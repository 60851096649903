import React, { useEffect } from 'react'
import { nanoid } from 'nanoid'
import { useReducer } from 'react'
import AudioTranscription from '../../../Components/AudioTranscription'
import { levels } from '../../../Components/utils/levels'
import ReducerQuiz, { reducerActions } from '../../../hooks/useReducerQuiz'
import { oralBasic } from '../../../Components/utils/comprension/quizes/basic/basic';
import { oralIntermediate } from '../../../Components/utils/comprension/quizes/intermediate/intermediate';
import { oralAdvanced } from '../../../Components/utils/comprension/quizes/advanced/advanced';
import { autoScroll } from '../../../utils/Autoscroll'
import Buttonss from '../../../Components/UI/Buttonss'
const initialState = {
    quiz_topic: null,
    show_quiz: false,
    show_levels: false,
    asign_level: null
}

const init = () => {
    return {
        initialState
    }
}
const Comprension = () => {
    useEffect(() => {
        console.log("me imprimo");
        autoScroll(0,700)
        return () => {
            
        }
    }, [])


    const handleShowQuiz = async (topic) => {
        dispatch({
            type: reducerActions.quizTopic,
            payload: topic
        });

    }

    const handleShowLevel = (level) => {
        const action = {
            type: reducerActions.showLevels,
            payload: level
        }
        dispatch(action);
    }

    const handleCloseQuiz = () => {
        dispatch({ type: reducerActions.hideQuiz });
    }
    const [{ show_levels, show_quiz, quiz_topic, asign_level }, dispatch] = useReducer(ReducerQuiz, initialState, init)

    return (
        <div className="container d-flex flex-column justify-content-center ">
            <h2 className='titleFont'>Compréhension orale</h2>
            <hr />
            <div className="container d-flex justify-content-evenly text-center">
                <div className="row">
                    {!show_quiz && <>
                        <div className="col-sm">
                            <Buttonss className="button" onClicks={() => handleShowLevel(levels.basic)}>Débutant</Buttonss>
                        </div>
                        <div className="col-sm">
                            <Buttonss className="button" onClicks={() => handleShowLevel(levels.intermediate)} >Intermédiaire</Buttonss>
                        </div>
                        <div className="col-sm">
                            <Buttonss className="button" onClicks={() => handleShowLevel(levels.avanzado)} >Avancé</Buttonss>
                        </div>
                    </>}
                </div>
            </div>
            <hr />
            <div className="container d-flex justify-content-evenly text-center mb-5">
                {show_levels && !show_quiz && asign_level === levels.basic &&
                    <div className="row w-100 justify-content-evenly">
                        {oralBasic.map(audio => {
                            return (<div className="col-sm" key={nanoid()}>
                                <Buttonss className="button button-topics" onClicks={() => handleShowQuiz(audio.data)}>{audio.title}</Buttonss>
                            </div>)
                        })}
                        <div className="col-sm">

                            <Buttonss className="button button-topics">
                                <a href={process.env.REACT_APP_DRIVE_ALUMNOSSERIE} target = "_blank" rel="noopener noreferrer">
                                    La Série - Parlez-vous Paris?
                                </a>
                            </Buttonss>
                        </div>
                    </div>
                }
                {show_levels && !show_quiz && asign_level === levels.intermediate &&
                    <div className="row w-100 justify-content-evenly">
                        {oralIntermediate.map(audio => {
                            return (<div className="col-sm" key={nanoid()}>
                                <Buttonss className="button button-topics" onClicks={() => handleShowQuiz(audio.data)}>{audio.title}</Buttonss>
                            </div>)
                        })}

                    </div>

                }
                {show_levels && !show_quiz && asign_level === levels.avanzado &&
                    <div className="row w-100 justify-content-evenly">
                        {oralAdvanced.map(audio => {
                            return (<div className="col-sm" key={nanoid()}>
                                <Buttonss className="button button-topics" onClicks={() => handleShowQuiz(audio.data)}>{audio.title}</Buttonss>
                            </div>)
                        })}
                    </div>
                }
                {quiz_topic && show_quiz &&
                    <div className="d-flex flex-column w-100 mb-5">
                        <AudioTranscription audio={quiz_topic.audio} quiz={quiz_topic.questions} transcription={quiz_topic.transcription} />
                        <Buttonss className="button mt-5" onClicks={() => { handleCloseQuiz() }}> Fermer</Buttonss>
                        {quiz_topic.credit && quiz_topic.creditText ?
                            <a className='text-dark' href={quiz_topic.credit} target="_blank" rel="noopener noreferrer"> {quiz_topic.creditText}</a>
                            :
                            <p>{quiz_topic.creditText}</p>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Comprension
