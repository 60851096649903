import React from 'react'
import { Link } from 'react-router-dom'
import Buttonss from '../../../Components/UI/Buttonss'


export const StudentStagePR = ({ url, level }) => {
    return (
        <>
            <div className="section__container--buttons">

                <div className="boton_down">
                    <Buttonss type="button" className="button"> <Link to={`${url}/clasesPR`}>
                    Material do curso
                    </Link> </Buttonss>
                </div>
                {/* <div className="boton_up">
                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_CLUB}>
                        Matériel du club de conversation
                    </Buttonss>
                </div> */}
            </div>
        </>)
}
