import {
    useState
} from 'react'

export const useCalificarState = () => {
    const [itemSelected, setItemSelected] = useState({
        one: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        },
        two: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        },
        three: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        },
        four: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        }
    });

    const [isDisabled, setIsDisabled] = useState({
        one: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        },
        two: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        },
        three: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        },
        four: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false
        }
    });

    
    const changeStateItem = (itemNumber, puntuacion) => {
        if(itemNumber === 1){
            if(puntuacion === 1){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveThree: false,
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveFour: !itemSelected.one.isActiveFour
                    }
                });
                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveThree: itemSelected.one.isActiveFour ? false : true,
                        isActiveone: itemSelected.one.isActiveFour ? false : true,
                        isActivetwo: itemSelected.one.isActiveFour ? false: true,
                        isActiveFour: !!isDisabled.one.isActiveFour
                    }
    
                })
            }
            if(puntuacion === 2){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveFour: false,
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.one.isActiveThree
                    }
                });

                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveFour: itemSelected.one.isActiveThree ? false : true,
                        isActiveone: itemSelected.one.isActiveThree ? false : true,
                        isActivetwo: itemSelected.one.isActiveThree ? false: true,
                        isActiveThree: !!isDisabled.one.isActiveThree
                    }
    
                })
            }
            if(puntuacion === 3){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveFour: false,
                        isActiveThree: false,
                        isActiveone: false,
                        isActivetwo: !itemSelected.one.isActivetwo
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveThree: itemSelected.one.isActivetwo ? false : true,
                        isActiveone: itemSelected.one.isActivetwo ? false : true,
                        isActiveFour: itemSelected.one.isActivetwo ? false: true,
                        isActivetwo: !!isDisabled.one.isActivetwo
                    }
    
                })
            }
            if(puntuacion === 4){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveone: !itemSelected.one.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveThree: itemSelected.one.isActiveone ? false : true,
                        isActiveFour: itemSelected.one.isActiveone ? false : true,
                        isActivetwo: itemSelected.one.isActiveone ? false: true,
                        isActiveone: !!isDisabled.one.isActiveone
                    }

                });
            }
        }
        if(itemNumber === 2){
            if(puntuacion === 1){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveThree: false,
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveFour: !itemSelected.two.isActiveFour
                    }
                });
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveThree: itemSelected.two.isActiveFour ? false : true,
                        isActiveone: itemSelected.two.isActiveFour ? false : true,
                        isActivetwo: itemSelected.two.isActiveFour ? false: true,
                        isActiveFour: !!isDisabled.two.isActiveFour
                    }
                })
            }
            if(puntuacion === 2){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.two.isActiveThree,
                        isActiveFour: false,
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveFour: itemSelected.two.isActiveThree ? false : true,
                        isActiveone: itemSelected.two.isActiveThree ? false : true,
                        isActivetwo: itemSelected.two.isActiveThree ? false: true,
                        isActiveThree: !!isDisabled.two.isActiveThree
                    }
    
                })
            }
            if(puntuacion === 3){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: false,
                        isActivetwo: !itemSelected.two.isActivetwo,
                        isActiveThree: false,
                        isActiveFour: false
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveThree: itemSelected.two.isActivetwo ? false : true,
                        isActiveone: itemSelected.two.isActivetwo ? false : true,
                        isActiveFour: itemSelected.two.isActivetwo ? false: true,
                        isActivetwo: !!isDisabled.two.isActivetwo
                    }
    
                })
            }
            if(puntuacion === 4){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        
                        isActiveone: !itemSelected.two.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveThree: itemSelected.two.isActiveone ? false : true,
                        isActiveFour: itemSelected.two.isActiveone ? false : true,
                        isActivetwo: itemSelected.two.isActiveone ? false: true,
                        isActiveone: !!isDisabled.two.isActiveone
                    }
                });
            }
        }
        if(itemNumber === 3){
            if(puntuacion === 1){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: !itemSelected.three.isActiveFour
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveThree: itemSelected.three.isActiveFour ? false : true,
                        isActiveone: itemSelected.three.isActiveFour ? false : true,
                        isActivetwo: itemSelected.three.isActiveFour ? false: true,
                        isActiveFour: !!isDisabled.three.isActiveFour
                    }
    
                })
            }
            if(puntuacion === 2){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.three.isActiveThree,
                        isActiveFour: false
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveFour: itemSelected.three.isActiveThree ? false : true,
                        isActiveone: itemSelected.three.isActiveThree ? false : true,
                        isActivetwo: itemSelected.three.isActiveThree ? false: true,
                        isActiveThree: !!isDisabled.three.isActiveThree
                    }
    
                })
            }
            if(puntuacion === 3){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: !itemSelected.three.isActivetwo,
                        isActiveThree: false,
                        isActiveFour: false,

                    }
                })

                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveThree: itemSelected.three.isActivetwo ? false : true,
                        isActiveone: itemSelected.three.isActivetwo ? false : true,
                        isActiveFour: itemSelected.three.isActivetwo ? false: true,
                        isActivetwo: !!isDisabled.three.isActivetwo
                    }
    
                })
            }
            if(puntuacion === 4){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: !itemSelected.three.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveThree: itemSelected.three.isActiveone ? false : true,
                        isActiveFour: itemSelected.three.isActiveone ? false : true,
                        isActivetwo: itemSelected.three.isActiveone ? false: true,
                        isActiveone: !!isDisabled.three.isActiveone
                    },
                    
                });
            }
        }
        if(itemNumber === 4){
            if(puntuacion === 1){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    four: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: !itemSelected.four.isActiveFour
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    four: {
                        isActiveThree: itemSelected.four.isActiveFour ? false : true,
                        isActiveone: itemSelected.four.isActiveFour ? false : true,
                        isActivetwo: itemSelected.four.isActiveFour ? false: true,
                        isActiveFour: !!isDisabled.four.isActiveFour
                    }
    
                })
            }
            if(puntuacion === 2){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    four: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.four.isActiveThree,
                        isActiveFour: false,
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    four: {
                        isActiveFour: itemSelected.four.isActiveThree ? false : true,
                        isActiveone: itemSelected.four.isActiveThree ? false : true,
                        isActivetwo: itemSelected.four.isActiveThree ? false: true,
                        isActiveThree: !!isDisabled.four.isActiveThree
                    }
    
                })
            }
            if(puntuacion === 3){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    four: {
                        isActiveone: false,
                        isActivetwo: !itemSelected.four.isActivetwo,
                        isActiveThree: false,
                        isActiveFour: false
                    }
                })
                setIsDisabled({
                    ...isDisabled,
                    four: {
                        isActiveThree: itemSelected.four.isActivetwo ? false : true,
                        isActiveone: itemSelected.four.isActivetwo ? false : true,
                        isActiveFour: itemSelected.four.isActivetwo ? false: true,
                        isActivetwo: !!isDisabled.four.isActivetwo
                    }
    
                })
            }
            if(puntuacion === 4){
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    four: {
                        isActiveone: !itemSelected.four.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                    }
                })
                setIsDisabled(
                    {
                        ...isDisabled,
                        four: {
                            isActiveThree: itemSelected.four.isActiveone ? false : true,
                            isActiveFour: itemSelected.four.isActiveone ? false : true,
                            isActivetwo: itemSelected.four.isActiveone ? false: true,
                            isActiveone: !!isDisabled.four.isActiveone
                        }
                    }
                        
                )
            }
        }
    }

    const calificarInfo = {
        headers: [
            "Catégories",
            4,
            3,
            2,
            1
        ]
        ,
        rows: [
            {
                categorie: "UTILISATION DES PHRASES ET GRAMMAIRE",
                options: [
                    {
                        itemNumber: 1,
                        text: "Il/ elle parle avec des phrases complètes parfaitement structurées avec grammaire et vocabulaire. (90-100%) ",
                        puntuacion: 4,
                        isActive: itemSelected.one.isActiveone,
                        disabled: isDisabled.one.isActiveone,
                    },
                    {
                        itemNumber: 1,
                        text: " Il/ elle parle avec des phrases bien complètes et structurées avec grammaire et vocabulaire.  (80-90%)",
                        puntuacion: 3,
                        isActive: itemSelected.one.isActivetwo,
                        disabled: isDisabled.one.isActivetwo,
                    },
                    {
                        itemNumber: 1,
                        text: "Il/ elle parle quelquefois avec des phrases complètes et structurées avec grammaire et vocabulaire. (70%-80%)",
                        puntuacion: 2,
                        isActive: itemSelected.one.isActiveThree,
                        disabled: isDisabled.one.isActiveThree,
                    },
                    {
                        itemNumber: 1,
                        text: "Il/ elle parle rarement avec des phrases complètes et structurées avec grammaire et vocabulaire. (50-60%)",
                        puntuacion: 1,
                        isActive: itemSelected.one.isActiveFour,
                        disabled: isDisabled.one.isActiveFour,
                    }
                ]
            },
            {
                categorie: "L’ÉTUDIANT(E) PARLE CLAIREMENT",
                options: [
                    {
                        itemNumber:2,
                        text: "Il/elle parle clairement et distinctement tout le temps avec une prononciation parfaite. (100-90%) ",
                        puntuacion: 4,
                        isActive:  itemSelected.two.isActiveone,
                        disabled: isDisabled.two.isActiveone,
                    },
                    {
                        itemNumber: 2,
                        text: "Il/elle parle clairement et distinctement tout le temps avec une bonne prononciation. (90-80%)    ",
                        puntuacion: 3,
                        isActive: itemSelected.two.isActivetwo,
                        disabled: isDisabled.two.isActivetwo,
                    },
                    {
                        itemNumber: 2,
                        text: "Il/elle parle clairement la partie principale du temps avec peu d’erreurs de prononciation ",
                        puntuacion: 2,
                        isActive: itemSelected.two.isActiveThree,
                        disabled: isDisabled.two.isActiveThree,
                    },
                    {
                        itemNumber: 2,
                        text: "Il/elle ne parle pas clairement et distinctement la partie principale du temps et les erreurs de prononciation interfèrent la communication",
                        puntuacion: 1,
                        isActive: itemSelected.two.isActiveFour,
                        disabled: isDisabled.two.isActiveFour,
                    }
                ]
            },
            {
                categorie: "LE TON DE LA VOIX",
                options: [
                    {
                        itemNumber: 3,
                        text: "Le ton de la voix utilisé exprime des émotions appropriées et donne une sécurité et/ou une prononciation parfaite au moment de parler. ",
                        puntuacion: 4,
                        isActive: itemSelected.three.isActiveone,
                        disabled: isDisabled.three.isActiveone,
                    },
                    {
                        itemNumber: 3,
                        text: "Le ton de la voix utilisé quelques fois exprime des émotions appropriées et donne sécurité et/ou une bonne prononciation au moment de parler. ",
                        puntuacion: 3,
                        isActive: itemSelected.three.isActivetwo,
                        disabled: isDisabled.three.isActivetwo,
                    },
                    {
                        itemNumber: 3,
                        text: "Le ton de la voix utilisé n’exprime pas d’émotions appropriées. De même façon, il ne donne pas de sécurité et de bonne prononciation au moment de parler",
                        puntuacion: 2,
                        isActive: itemSelected.three.isActiveThree,
                        disabled: isDisabled.three.isActiveThree,
                    },
                    {
                        itemNumber: 3,
                        text: "Le ton n’était pas utilisé pour exprimer des émotions appropriées. De même, il ne donne pas de sécurité et/ou de bonne prononciation au moment de parler.",
                        puntuacion: 1,
                        isActive: itemSelected.three.isActiveFour,
                        disabled: isDisabled.three.isActiveFour,
                    }
                ]
            },
            {
                categorie: "LA COMPRÉHENSION AUDITIVE ET ORALE	",
                options: [
                    {
                        itemNumber: 4,
                        text: "L’étudiant(e) répond avec précision à toutes les questions posées.   ",
                        puntuacion: 4,
                        isActive: itemSelected.four.isActiveone,
                        disabled: isDisabled.four.isActiveone,
                    },
                    {
                        itemNumber: 4,
                        text: "L’étudiant(e) réponde avec précision les questions principales posées",
                        puntuacion: 3,
                        isActive: itemSelected.four.isActivetwo,
                        disabled: isDisabled.four.isActivetwo,
                    },
                    {
                        itemNumber: 4,
                        text: "L’étudiant(e) réponde avec précision seulement quelques questions posées",
                        puntuacion: 2,
                        isActive: itemSelected.four.isActiveThree,
                        disabled: isDisabled.four.isActiveThree,
                    },
                    {
                        itemNumber: 4,
                        text: "L’étudiant(e) ne réponde pas avec précision aucunes des questions posées",
                        puntuacion: 1,
                        isActive: itemSelected.four.isActiveFour,
                        disabled: isDisabled.four.isActiveFour,
                    }
                ]
            },
        ]
    }

    return {
        itemSelected,
        changeStateItem,
        calificarInfo
    }
}