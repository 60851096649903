import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

export const CourseLvl = ({ lvl, changeStatusLvl }) => {
    const { level, content, isActive, clase, id } = lvl;
    return (
        <div className="mt-5">
            <div className="card-container d-flex flex-column align-items-center justify-content-center  pb-5 fatherRelative">
                <button
                    className='a'
                    onClick={() => changeStatusLvl(level)}
                >
                    {level.trim().toUpperCase()}
                </button>
                {
                    isActive ?
                        (<>
                            <div className='signerBall down' ></div>

                        </>
                        )
                        : null
                }
            </div>
        </div>
    )
}


CourseLvl.propTypes = {
    lvl: PropTypes.object.isRequired
}