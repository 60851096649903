import React, { useState } from 'react'
import QuizComponent from './QuizComponent';
import {Card} from 'react-bootstrap';
import { nanoid } from 'nanoid';

const AudioTranscription = ({ audio, quiz, transcription }) => {
    const [ejercicio, setEjercicio] = useState(true);
    return (
        <div className='w-100'>



            <audio controls>
                <source src={audio} type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>

            <hr />
            <div className='row w-100 justify-content-evenly mb-5'>
                <div onClick={() => setEjercicio(true)} className = "col" >
                    <h3 className='fs-4 pointer button'>Exercice</h3>
                </div>
                <div onClick={() => setEjercicio(false)} className = "col">
                    <h3 className='fs-4 pointer button'>Transcript</h3>
                </div>
            </div>
            {
                ejercicio ?
                    <div className="ejercicios">
                        <QuizComponent questions={quiz}/>
                    </div>
                    :
                    <div className="transcript text-start">
                        {/* <p className='w-70 text-start container'>
                            {transcription}
                        </p> */}
                        <Card style = {{maxWidth: '38em', margin: "0 auto", borderRadius: "10px"}}>
                            <Card.Body>
                                {
                                    transcription ?
                                    transcription?.map(item=> (
                                        <div key = {nanoid()}>
                                            <p>{item}</p>
                                            <br />
                                        </div>
                                    )) :
                                    <p className='text-center'>No hay transcripción para este audio :(</p>
                                }
                            </Card.Body>
                        </Card>
                    </div>
            }
        </div>
    )
}

export default AudioTranscription
