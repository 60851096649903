import React, {useEffect} from 'react'
import { autoScroll } from '../../../utils/Autoscroll';
import Buttonss from '../../../Components/UI/Buttonss';

const Pronunciacion = () => {
    useEffect(() => {
        console.log("me imprimo");
        autoScroll(0,700)
        return () => {
            
        }
    }, [])

    return (
        <div className="container justify-content-center">
            <h2 className="titleFont">Phonéthique Essentielle du Français </h2>
            <hr />
            <div className='container d-flex justify-content-evenly text-center'>
                <div className="row">
                    <div className="col-sm">
                        <Buttonss className="button button-topics" ><a href="https://drive.google.com/file/d/10d4EvQKNEnf7QYkhkAECzKTiRzS4MQsI/view?usp=sharing" target="_blank" rel="noopener noreferrer">Livre d'étude</a></Buttonss>
                    </div>
                    <div className="col-sm">
                        <Buttonss className="button button-topics" ><a href="https://drive.google.com/drive/folders/1tyHLqNh4eYlIldHaD4c0K3y-0zhOb2MQ?usp=sharing" target="_blank" rel="noopener noreferrer">Audios du livre d'étude</a></Buttonss>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Pronunciacion
