const faireLaPlonge = [{
    questionText: "Élisa a eu un seul job d'été",
    answerOptions: [{
            answerText: "Vrai",
            isCorrect: false
        },
        {
            answerText: "Faux",
            isCorrect: true
        },
    ]
},
{
    questionText: "'Faire la plonge' signifie",
    answerOptions: [{
            answerText: "Faire la vaisselle dans un restaurant",
            isCorrect: true
        },

        {
            answerText: "Faire la lessive dans un restaurant",
            isCorrect: false
        },
        {
            answerText: "Faire le ménage dans un restaurant",
            isCorrect: false
        },
    ]
},
{
    questionText: "Élisa travaillait seulement le matin",
    answerOptions: [{
            answerText: "Vrai",
            isCorrect: false
        },
        {
            answerText: "Faux",
            isCorrect: true
        },

    ]
},
{
    questionText: "D'après Élisa, quelles sont les qualités nécessaires pour ce job ?",
    answerOptions: [{
            answerText: "La créativité, la patience, la sincérité",
            isCorrect: false
        },
        {
            answerText: "La prudence, la rapidité, l'adresse",
            isCorrect: true
        },
        {
            answerText: "La gentillesse, la flexibilité, la ponctualité",
            isCorrect: false
        },

    ]
},
{
    questionText: "Une 2e partie de ce job consistait à réaliser",
    answerOptions: [{
            answerText: "des animations",
            isCorrect: false
        },
        {
            answerText: "des bouquets de fleurs",
            isCorrect: false
        },

        {
            answerText: "des cocktails",
            isCorrect: true
        },
    ]
},
{
    questionText: "Pour Élisa, ce job",
    answerOptions: [{
            answerText: "était parfait",
            isCorrect: false
        },
        {
            answerText: "lui a plu mais il avait des inconvénients",
            isCorrect: true
        },

        {
            answerText: "était horrible",
            isCorrect: false
        },
    ]
},

]

export {
faireLaPlonge
};