export const validarEmail = (values)=>{
    let errors = {};
    if (!values.email) {
        errors.email = "Por favor ingrese su correo";
    } 
    else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.email)) {
        errors.email = "El correo solo puede contener letras, numeros, guiones y guion bajo";
    }

    if (!values.password) {
        errors.password = "Por favor Ingrese su contraseña";
    } 

    return errors;
}