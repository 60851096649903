import React, { useEffect } from 'react'
// import {useLocation } from 'react-router';
import LoginComponent from '../../Components/Login';
import SVG from './design-inspiration-animate.svg';
import { SideToSide } from '../../Components/UI/SideToSide';
import SVGM from './maintance.svg';
import { dataMantenimiento } from '../../Components/utils/data';
import { UseFetch } from '../../hooks/useRequest';
export default function LoginPage() {
    const [responseMaintainance, fetchPromo, fetchStudentNotificationFromFirebase] = UseFetch();
    useEffect(() => {
        
    }, [])
    return (
        <>
{
    true ? 
    <div className="d-flex justify-content-center align-items-center LoginContainer" style={{ height: "100vh", position: "relative" }}>
                <div className='filter'></div>
                <LoginComponent />
                <div className="floating">
                    <SideToSide embed={SVG} Mark={false} />
                </div>
                <div className="alert alert-warning alert-dismissible fade show form-alert" role="alert">
                    <strong>Recuerda que</strong> si aún no tienes tu usuario, puedes solicitarlo a administración de Go Go Academy
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <a href="https://www.pexels.com/es-es/foto/silla-al-lado-de-estantes-para-libros-2041540/" target="_blank" rel="noopener noreferrer" className='text-light fs-6' style={{ position: "absolute", bottom: 0, left: 0 }}>Foto de Rafael Cosquiere en Pexels</a>
                </div> : <div style={{marginTop: "50px"}}>
                <SideToSide embed={SVGM} Mark={false}  leftToRight={true} dataHome={dataMantenimiento}/>
                </div>
            }
            </>
    )
}
