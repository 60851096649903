import React from 'react';
import { SideToSide } from '../../Components/UI/SideToSide';
import "bootstrap/dist/css/bootstrap.min.css";
import SVG from './Nosotros.svg';
import { dataNosotros } from '../../Components/utils/data';

export default function Nosotros(){
    return(
        <div> 
        <div style = {{width: "100%", height: "100px"}}></div>
        <SideToSide embed = {SVG} dataHome = {dataNosotros} Mark = {false} leftToRight={false}  />
        </div>
    )
}