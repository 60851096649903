import React, { useEffect } from 'react'
import { autoScroll } from '../../../utils/Autoscroll';
import Buttonss from '../../../Components/UI/Buttonss';

const ControlScreen = () => {
    useEffect(() => {
        console.log("me imprimo");
        autoScroll(0, 700)
        return () => {

        }
    }, [])

    return (
        <div className="container justify-content-center">
            <h2 className="titleFont">Control du profs </h2>
            <hr />
            <div className='container d-flex justify-content-evenly text-center'>
                <div className="row">
                    <div className="col-sm">
                        <Buttonss className=" button-topics" isLink url={process.env.REACT_APP_DRIVE_TRACKHOURS} >Track Horas de trabajo</Buttonss>
                    </div>
                    <div className="col-sm">
                        <Buttonss className=" button-topics" isLink url={process.env.REACT_APP_DRIVE_REPORTEASISTENCIA} >Reportar asistencia (Staff)</Buttonss>
                    </div>
                    <div className="col-sm">
                        <Buttonss className="button button-topics" isLink url={process.env.REACT_APP_DRIVE_ASISTENCIA_ALUMNOS}  >Asistencia - Tracker (Estudiantes)</Buttonss>
                    </div>
                    <div className="col-sm">
                        <Buttonss className="button  button-topics" isLink url={process.env.REACT_APP_DRIVE_REGISTRO_NOTASTEMPLATE}>Registro de notas - (Template)</Buttonss>
                    </div>
                    <div className="col-sm">
                        <Buttonss className="button button-topics" isLink url={process.env.REACT_APP_DRIVE_EVALUACIONESPROF}>Evaluaciones</Buttonss>
                    </div>
                </div>
            </div>

        </div>)
}

export default ControlScreen