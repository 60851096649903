const leBonheur = [{
    questionText: "Un salarié heureux",
    answerOptions: [{
        answerText: "est moins absent",
        isCorrect: false
    },
    {
        answerText: "travail plus",
        isCorrect: true
    },
    {
        answerText: "est plus sociable",
        isCorrect: false
    },
    ]
},
{
    questionText: "Le(s) rôle(s) du responsable du bonheur :",
    answerOptions: [{
        answerText: "organiser des apéros",
        isCorrect: false
    },

    {
        answerText: "créer de la convivialité && écouter les employés",
        isCorrect: true
    },

    ]
},
{
    questionText: "La principale cause des problèmes en entreprise vient :",
    answerOptions: [{
        answerText: "des employés",
        isCorrect: true
    },
    {
        answerText: "du travail",
        isCorrect: false
    },
    {
        answerText: "de la direction",
        isCorrect: true
    },
    ]
},
{
    questionText: "Les entreprises des nouvelles technologies adoptent plus facilement le bonheur au travail que les entreprises traditionnelles. ",
    answerOptions: [{
        answerText: "Vrai",
        isCorrect: true
    },
    {
        answerText: "Faux",
        isCorrect: false
    },
    ]
},
{
    questionText: "Les managers seront bientôt obligés d'appliquer des mesures de bonheur au travail.",
    answerOptions: [{
        answerText: "Vrai",
        isCorrect: true
    },
    {
        answerText: "Faux",
        isCorrect: false
    },

    ]
},

]

export {
    leBonheur
};