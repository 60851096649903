import React, { useEffect } from "react";
import About from "../Screens/about/About";
import Nosotros from "../Screens/Nosotros/Nosotros";
import Material from "../Screens/Material/Material";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/Home/Home";
import NotFound from "../Screens/not_found/NotFound";
import Header from "../Components/header/Header";
import LoginPage from "../Screens/login_page/LoginPage";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./publicRoute";
import Footer from "../Components/Footer/Footer";
import { TermsAndContitions } from "../Screens/TermsAndContitions";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestorage } from "../firebase/firebaseConfig";
import { login, startLevelAndRol, startLogout } from "../actions/auth";
import { getDoc, doc } from 'firebase/firestore';
import TeachersPage from "../Screens/Teachers/Teachers";
import { FaWhatsapp, } from 'react-icons/fa';
import { BsMessenger } from 'react-icons/bs';

import OnlyTeachers from "./onlyTeachers";
import { FloatingMedia } from "../Components/UI/FloatingMedia";
import { videosScreen } from "../Screens/videos/videosScreen";
import { handleGetLevelAndRol } from "../services/index.services";
import Swal from "sweetalert2";
function Routes() {



  const dispatch = useDispatch();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        handleGetLevelAndRol(user.uid).then(item => {
          if (item.status !== "Inactivo") {
            dispatch(login(item));
            return;
          }
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: 'Tu usuario ha sido bloqueado, contacta con administración para obtener mas información',
          }).then(() => dispatch(startLogout()))
        })
      }
    })
    return () => {
    }
  }, [dispatch])
  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route exact path="/about" component={About} />
          <Route path="/terms" exact component={TermsAndContitions} />
          <Route path="/Nosotros" exact component={Nosotros} />
          <Route path="/videos" exact component={videosScreen} />
          <PrivateRoute component={Material} path="/Material" />
          <OnlyTeachers component={TeachersPage} path="/Teachers" />
          {/* <PrivateRoute  component = {PaymentPage} path = "/Payment" exact/> */}
          <PublicRoute path="/login" exact component={LoginPage} />
          <Route path="/" exact component={Home} />
          <Route path="/index.html" exact component={Home} />
          <Route path="*" exact component={NotFound} />
        </Switch>
        <FloatingMedia currentIcon={<FaWhatsapp />} url2="https://m.me/GoGo.Academy.sv" currentIcon2={<BsMessenger />} url={"https://bit.ly/academiadeidiomasv"} />

        <Footer />
      </div>
    </Router>
  );
}

export default Routes;
