import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import OnlyTeachersRoute from '../../routes/teachersRoutes';
const TeachersPage = () => {
    let { url, path } = useRouteMatch();
    return (
        <OnlyTeachersRoute path={path} url={url} />
    );
};

export default TeachersPage;
