import React, { useEffect, useReducer } from 'react'
import QuizComponent from '../../../Components/QuizComponent'
import { levels } from '../../../Components/utils/levels'
import { questions } from '../../../Components/utils/quiz/basic_quiz'
import ReducerQuiz, { reducerActions } from '../../../hooks/useReducerQuiz'
import { autoScroll } from '../../../utils/Autoscroll'
import Buttonss from '../../../Components/UI/Buttonss'

const init = () => {
    return {
        ...initialState
    }
}
const initialState = {
    quiz_topic: null,
    show_quiz: false,
    show_levels: false,
}


const TestLevel = () => {
    useEffect(() => {
        console.log("me imprimo");
        autoScroll(0,700)
        return () => {
            
        }
    }, [])

    const [{ quiz_topic, show_quiz, show_levels }, dispatch] = useReducer(ReducerQuiz, initialState, init);

    const handleShowQuiz = (topic) => {
        dispatch({
            type: reducerActions.quizTopic,
            payload: topic
        });
    }
    const handleCloseQuiz = () => {
        dispatch({ type: reducerActions.hideQuiz });
    }

    const handleShowLevel = (level) => {
        const action = {
            type: reducerActions.showLevels,
            payload: level
        }
        dispatch(action);
    }
    return (
        <div className="container d-flex flex-column justify-content-center ">
            <h2 className='titleFont'>Testez votre niveau de français</h2>
            <hr />
            <h3 className="text-start">
                Exercices
            </h3>
            <hr />
            <div className='container d-flex justify-content-evenly text-center'>
                <div className="row">
                    {!show_quiz && <>
                        <div className="col-sm">
                            <Buttonss className="button" onClicks={() => handleShowLevel(levels.basic)}>Débutant</Buttonss>
                        </div>
                        <div className="col-sm">
                            <Buttonss className="button">Intermédiaire</Buttonss>
                        </div>
                        <div className="col-sm">
                            <Buttonss className="button">Avancé</Buttonss>
                        </div>
                    </>}
                </div>
            </div>
            <hr />
            <div className="container d-flex justify-content-evenly text-center">
                {show_levels && !show_quiz &&
                    <div className="row">
                        <div className="col-sm">
                            <Buttonss className="button" onClicks={() => handleShowQuiz(questions)}>Topic</Buttonss>
                        </div>
                        <div className="col-sm" >
                            <Buttonss className="button">Topic</Buttonss>
                        </div>
                        <div className="col-sm">
                            <Buttonss className="button">Topic</Buttonss>
                        </div>
                    </div>
                }

                {quiz_topic && show_quiz &&
                    <div className="d-flex flex-column w-100">
                        <QuizComponent questions={quiz_topic} />
                        <Buttonss className="button" onClicks={() => { handleCloseQuiz() }}> Fermer</Buttonss>
                    </div>
                }
            </div>
        </div>
    )
}

export default TestLevel
