import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Buttonss from "../UI/Buttonss";
import { useForm } from "../../hooks/useForm";
import { validarEmail } from "../utils/emailValidation";
import Swal from "sweetalert2";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";

export default function ModalComponent({
    show,
    setShow,
    title,
}) {
    const [{ email }, handleInputChange] = useForm({
        email: ""
    });
    const handleClose = () => setShow(false);
    const handleResetPassword = async () => {
        const emailText = validarEmail({ email }).email;
        if (emailText) {
            Swal.fire({
                title: "¡Atencion!"
                , text: emailText,
                icon: "warning"
            })
            return;
        }
        await sendPasswordResetEmail(auth, email).then(() => {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: "Se ha enviado un correo para reestablecer la contraseña, si no ves el correo en la bandeja principal, revisa en SPAM"
            }).then(()=> handleClose())
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            switch(errorCode){
                case "auth/user-not-found":
                    Swal.fire({
                        title: "Error",
                        text: "El correo ingresado no esta registrado en Go Go Academy"
                        ,icon: "error"
                    })

            }
            console.log({ errorCode, errorMessage })
        })
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                placeholder="name@example.com"
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button" onClick={() => handleResetPassword()}>
                        Enviar correo
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
