import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { UseFetch } from './useRequest';
import { startLogout } from '../actions/auth';
import Swal from 'sweetalert2';

import { getCuentos, getLibros, getNofiticationStudents } from '../Components/utils/Path';
import { LecturaContext } from '../lecturaProvider/LecturaProvider';
export const UseCallStudentPromo = () => {
    const {
        setCuentos,
        setBooks
    } = useContext(LecturaContext);
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const [callDocs, setCallDocs] = useState(auth.uid ? true : false);
    const [responseCuentos, fecthNormalCuentos, fetchCuentosFirebase] = UseFetch();
    const [responseLibros, fecthNormalLibros, fetchLibrosFirebase] = UseFetch();
    const [responseStudentNotification, fetchPromo, fetchStudentNotificationFromFirebase] = UseFetch();
    useEffect(() => {
        fetchStudentNotificationFromFirebase(getNofiticationStudents(), false);
    }, []);

    useEffect(() => {
        let notificacionAnterior = localStorage.getItem('lastNot') ?? "";
        if (responseStudentNotification.isSuccess) {
            if (responseStudentNotification.response.id.toString() !== notificacionAnterior.toString() || notificacionAnterior === "") {
                Swal.fire({
                    imageUrl: responseStudentNotification.response.imagen,
                    backdrop: `rgba(63,61,86, 0.5)`,
                    allowOutsideClick: false,
                    confirmButtonColor: "#ff9900",
                    confirmButtonText: "OK",
                })
                localStorage.setItem('lastNot', JSON.stringify(responseStudentNotification.response.id))
            }
        }
    }, [responseStudentNotification]);

    useEffect(() => {
        if (responseCuentos.isSuccess) {
            setCuentos([
                ...responseCuentos.response.cuentos
            ])
        }
    }, [responseCuentos]);
    useEffect(() => {
        if (responseLibros.isSuccess) {
            setBooks([
                ...responseLibros.response.book
            ]);
        }
    }, [responseLibros]);

    useEffect(() => {
        if (auth.uid !== "") {
            fetchCuentosFirebase(getCuentos());
            fetchLibrosFirebase(getLibros())
        }

    }, [auth]);

    useEffect(() => {

        return () => {
            setCallDocs(false);
        }
    }, [])

    const handleLogout = () => {
        dispatch(startLogout());
    }
    return { handleLogout }

}
