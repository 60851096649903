import React, { forwardRef } from 'react';
import { useState } from 'react';
import { createRef } from 'react';

import { Table } from 'react-bootstrap';
import Pdf from 'react-to-pdf';
import { useForm } from '../../../../../hooks/useForm';
import logotipo from '../assets/logoBlack.png';
import { useCalificarAdvanceState } from '../hooks/useCalificarAdvance';
import { useCalificarAdvance2State } from '../hooks/useCalificarAdvance2';
import GrillaCompetencia from './grillaCompetencia';
import ReactDatePicker from 'react-datepicker';


const CalificarAdvance = () => {
    const [nota, setNota] = useState(0);
    const [nota2, setNota2] = useState(0);
    const { changeStateItem, calificarInfo } = useCalificarAdvanceState();
    const { changeStateItem: changeStateItem2, calificarInfo: calificarInfo2 } = useCalificarAdvance2State();
    const [{ name, nameTeacher, group, comment }, handleInputchange] = useForm({
        name: "",
        nameTeacher: "",
        group: "",
        comment: ""
    });
    const ref = createRef();
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ margin: "0 auto", marginLeft: "40px" }}>
            <div ref={ref}>
                <div className="p-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <label htmlFor="name">Nom de l’étudiant:</label>
                        <input

                            type="text"
                            placeholder='Escriba aqui el nombre del estudiante'
                            className="input_withoutB"
                            name="name"
                            value={name}
                            onChange={handleInputchange}
                            id="name"
                            autoComplete="off"
                        />
                    </div>

                    <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <label htmlFor="nameTeacher">Nom du professeur :</label>
                            <input
                                type="text"
                                placeholder='Escriba aqui el nombre del Profesor'
                                className="input_withoutB"
                                name="nameTeacher"
                                value={nameTeacher}
                                onChange={handleInputchange}
                                id="nameTeacher"
                                autoComplete="off"

                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <label htmlFor="group text-bold">Groupe #:</label>
                            <input
                                type="text"
                                placeholder='Numero de grupo'
                                className="input_withoutB-small"
                                name="group"
                                value={group}
                                onChange={handleInputchange}
                                id="group"
                                autoComplete="off"
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <label htmlFor="group text-bold">Niveau:</label>
                            <input
                                type="select"
                                placeholder='Nivel del estudiante'
                                className="input_withoutB-small"
                                autoComplete="off"
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <label htmlFor="group text-bold">Date:</label>
                            <div className='w-100'></div>
                            <div className='w-50'>
                                <ReactDatePicker
                                    className='input_withoutB'
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                        </div>
                    </div>
                    <h1 style={{ color: "#3f3d56" }}>Exposé Dirigé</h1>
                    {/* <TableComponentAdvanced setNota={setNota} nota={nota} calificarInfo={calificarInfo} changeStateItem={changeStateItem} /> */}
                    <GrillaCompetencia info={calificarInfo.rows} nota={nota} setNota={setNota} changeStateItem={changeStateItem} />
                    <h1 style={{ color: "#3f3d56", margin: "20px 0" }}> Compétences linguistiques</h1>
                    <GrillaCompetencia info={calificarInfo2.rows} nota={nota2} setNota={setNota2} changeStateItem={changeStateItem2} />
                    {/* <TableComponentAdvanced setNota={setNota2} nota={nota2} calificarInfo={calificarInfo2} changeStateItem={changeStateItem2} /> */}
                    <Table striped bordered size='sm' style={{ maxWidth: "900px" }}>
                        <thead>
                            <tr>

                                <th>Points obtenus:</th>
                                <th>{`${nota + nota2}/17`}</th>
                            </tr>
                        </thead>
                    </Table>
                    <h1 className='text-center'>Note: {`${((10 / 17) * (nota + nota2)).toString().substring(0, 4)}`}</h1>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                            <label htmlFor="group text-bold">commentaires:</label>
                            <textarea
                                placeholder='Sin comentarios'
                                className="textarea"
                                name="comment"
                                value={comment}
                                onChange={handleInputchange}
                                id="group"
                                cols="50"
                                rows="10"
                            ></textarea>
                        </div>
                        <img src={logotipo} alt="Logotipo de go go academy" className="logotipo2" />
                    </div>
                </div>
            </div>
            <Pdf targetRef={ref} filename={`${name.split(" ").join("") ?? "Student"}Exam.pdf`} scale={0.8}>
                {
                    ({ toPdf }) => <button className='button' onClick={toPdf} >Descargar Pdf</button>
                }
            </Pdf>
        </div>
    )
}
export default CalificarAdvance;
