import React from 'react'
import { types } from '../types/types';

const INITIAL_STATE = {
    isLoaded: false,
    data: [
        { descripcion: "", url: "" },
    ]
}

export const VideosReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.loadVideos:
            return {
                isLoaded: true,
                data: action.payload
            }
        default:
            return state;
    }
}
