import React from 'react'
import { Link } from 'react-router-dom'
import Buttonss from '../../../Components/UI/Buttonss'


export const StudentStageEN = ({ url, level }) => {
    return (
        <>
            <div className="section__container--buttons">
                <div className="boton_down">
                    <Buttonss type="button" className="button"> <Link to={`${url}/course`}>
                        Course material
                    </Link> </Buttonss>
                </div>
                <div className="boton_up">
                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_BOOKS_EN}>
                    Books
                    </Buttonss>
                </div>
                <div className="boton_up">
                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_CONVERSATION_CLUB_EN}>
                    Club
                    </Buttonss>
                </div>
            </div>
        </>)
}
