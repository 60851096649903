const Bitcoin = [{
    questionText: "Au Salvador, le bitcoin est devenu une monnaie officielle:",
    answerOptions: [{
        answerText: "depuis 2 mois",
        isCorrect: true
    },
    {
        answerText: "epuis 2 ans",
        isCorrect: false
    },

    ]
},
{
    questionText: "Le Salvador projette de construire une ville:",
    answerOptions: [{
        answerText: "en partie basée sur le bitcoin.",
        isCorrect: false
    },

    {
        answerText: "complètement basée sur le bitcoin.",
        isCorrect: true
    },

    ]
},
{
    questionText: "Qui a annoncé le lancement du projet de ville ?",
    answerOptions: [{
        answerText: "Nayib Bukele, le président du Salvador",
        isCorrect: true
    },
    {
        answerText: "Satoshi Nakamoto, le créateur du bitcoin",
        isCorrect: false
    },
    ]
},
{
    questionText: "Le but de ce projet est que le Salvador devienne :",
    answerOptions: [{
        answerText: "« un paradis fiscal. »",
        isCorrect: false
    },
    {
        answerText: "« le centre financier du monde. »",
        isCorrect: true
    },
    ]
},
{
    questionText: "La ville sera construite près d'un volcan, cela permettra :",
    answerOptions: [{
        answerText: "de développer le tourisme.",
        isCorrect: false
    },
    {
        answerText: "d'utiliser l'énergie géothermique.",
        isCorrect: true
    },

    ]
},
{
    questionText: " La seule taxe exigée sera :",
    answerOptions: [{
        answerText: "la TVA (taxe sur la valeur ajoutée).",
        isCorrect: true
    },
    {
        answerText: "la taxe foncière.",
        isCorrect: false
    },
    ]
},


]

export {
    Bitcoin
};