import React from 'react'
import CalificarAdvance from './components/CalificarAdvance'

const RubricAdvace = () => {
    return (
        <div style={{ marginTop: "2em" }}>
            <CalificarAdvance />
        </div>
    )
}

export default RubricAdvace