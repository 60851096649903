import React from 'react'
import { Link } from 'react-router-dom';
import { autoScroll } from '../../utils/Autoscroll';
import SVG from './knowledge-animate.svg';
import Buttonss from '../UI/Buttonss';
import { useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';


const TeacherHeader = ({
    level,
    url,
}) => {
    const auth = useSelector((x) => x.auth);
    return (
        <div className="main">
            <section className="section">
                <div className="section__container--img">
                    <img src={SVG} className="container__img" alt="Libro color naranja flotando con un circulo transparente el la parte de atras" />
                </div>
                <div className='d-flex  flex-column justify-content-center align-items-center'>
                    <h3 className='text-center'>
                        Bienvenu(e) {auth.name}!
                        <br />
                        <Badge bg="dark">Professeur</Badge>

                    </h3>
                </div>
                <div className="section__container--buttons">
                    <div className="boton_up">
                        <Buttonss type="button" className="button" onClicks={() => {
                            autoScroll(0, 500);
                        }}><Link to={`${url}/rubrica-advance`}>Grille d'évaluation (Avancé)</Link> </Buttonss>
                        <Buttonss type="button" className="button" onClicks={() => {
                            autoScroll(0, 500);
                        }}><Link to={`${url}/rubrica`}>Grille d'évaluation</Link> </Buttonss>
                        <Buttonss type="button" className="button"><Link to={`${url}/control`}>Control</Link> </Buttonss>
                    </div>
                    <div className="boton_down">
                        <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_ONBOARDING}>
                                Estrategias de aprendizaje y enseñanza
                        </Buttonss>
                    </div>
                </div>
            </section>
        </div>)
}

export default TeacherHeader;