export const reducerActions = {
    showQuiz: "show Quiz",
    hideQuiz: "hide quiz",
    quizTopic: "asign topic to quiz",
    showLevels: "show levels",
    hideLevels: 'hide levels',
    asignLevel: "asign level"
}

const ReducerQuiz = (state, action) => {
    switch (action.type) {
        case reducerActions.hideQuiz:
            return {
                ...state,
                show_quiz: false
            }
            case reducerActions.quizTopic:
                return {
                    ...state,
                    quiz_topic: action.payload,
                        show_quiz: true
                }
                case reducerActions.showQuiz:
                    return {
                        ...state,
                        show_quiz: true
                    }
                    case reducerActions.showLevels:
                        return {
                            ...state,
                            show_levels: true,
                                asign_level: action.payload
                        }
                        case reducerActions.hideLevels:
                            return {
                                ...state,
                                show_Levels: false
                            }
                            default:
                                return state;
    }
}

export default ReducerQuiz