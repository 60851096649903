import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { UseFetch } from '../hooks/useRequest'
import Testimonios from './Testimonios/Testimonios';
import { getVideos } from './utils/Path';

export const VideosRender = () => {
    const [stateVideos, setStateNormal, setStateFromFirebase] = UseFetch();
    const isLoaded = useSelector(state => state.videos);
    useEffect(() => {
        console.log("isloaded:", isLoaded);
        if (isLoaded.isLoaded) return;
        setStateFromFirebase(getVideos(), false);
    }, [isLoaded])

    useEffect(() => {
        console.log("isloaded:", isLoaded);

        if (stateVideos.isSuccess) {
            console.log("stateVideos:", stateVideos);
        }
        
    }, [stateVideos])

    return stateVideos.isSuccess ? (stateVideos.response.videos.map((video => <Testimonios embed={video.url} />)))
        :
        <div></div>
}
