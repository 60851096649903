import React from 'react'
import guate from './guatemala.png';
import nicaragua from './nicaragua.png';
import honduras from './honduras.png';
import panama from './panama.png';
import salvador from './elsalvador.png';

export default function MoldeImg({ pais}) {
    let ruta;
    switch (pais){
        case "guatemala":
            ruta = guate;
            break;
        case "honduras":
            ruta = honduras;
            break;
        case "panama":
            ruta = panama;
            break;
        case "nicaragua":
            ruta = nicaragua;
            break;
        default:
            ruta = salvador;
            break;
    }
    return (
        <div className="img-container">
            <img  className = "img-container__img"src = {ruta} alt= {"bandera circular de " + pais} />
        </div>
    )
}
