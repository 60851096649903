import React, { createContext, useState } from 'react'

export const LecturaContext = createContext();

const LecturaProvider = ({children}) => {
    
    const [books, setBooks] = useState([]);
    const [cuentos, setCuentos] = useState([]);
    const readInfo = {
        books,
        cuentos,
        setBooks,
        setCuentos
    }
    return (
        <LecturaContext.Provider value = {readInfo}>
            {children}
        </LecturaContext.Provider>
    )
}

export default LecturaProvider
