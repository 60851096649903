import React from "react";
import { Link } from "react-router-dom";
import logo from "./logotipo.png";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="footer__container">
          <section
            className="d-flex justify-content-evenly w-100 pt-3"
          >
            <h3 className="align-self-center m-0 fs-4" style={{ color: "#fff" }}>
              POR UN MUNDO SIN FRONTERAS
            </h3>
            <ul className="d-flex social-items justify-content-evenly align-items-center h-100 m-0 fs-2 ">
              <li>
                <a href="https://www.facebook.com/GoGo.Academysv" target="_blank" rel="noopener noreferrer">
                  <BsFacebook />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/gogo.academy/" target="_blank" rel="noopener noreferrer">
                  <BsInstagram />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCK4s8nLckozQSWAtTXsd7BQ" target="_blank" rel="noopener noreferrer">
                  <BsYoutube />
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@gogoacademy?" target="_blank" rel="noopener noreferrer">
                  <FaTiktok />
                </a>
              </li>
            </ul>
          </section>
          <hr className="text-white" />
          <section className="d-flex p-0 justify-content-evenly end-footer align-items-md-center">
            <Link className="socialItem" to="/">
              <img
                src={logo}
                className="logoImg"
                alt="el Logotipo de la academia"
              />
            </Link>
            <div>
              <ul>
                <li>
                <Link to = "/Nosotros">¿Quienes somos?</Link>
                </li>
                {/* <li>
                  <a href="#">Ponte en contacto con nosotros</a>
                </li> */}
              </ul>
            </div>
            <div>
              <ul>
                {/* <li>
                  <a href="#">Blog</a>
                </li> */}
                <li>
                  <Link to="/Material">Material</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li >
                  <Link to="/terms">Términos y condiciones</Link>
                </li>
                <li>
                <a href="https://storyset.com/work" target="_blank" rel="noopener noreferrer">Work illustrations by Storyset</a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </footer>
    </div>
  );
}
