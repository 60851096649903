import React, { useEffect } from 'react';
import { autoScroll } from '../../../../utils/Autoscroll';
import ClasePRCompnent from './claseENComponent';


const ClasesPR = () => {
    useEffect(() => {
        autoScroll(0,700)
        return () => {
            
        }
    }, [])

    return (
        <div className="container d-flex flex-column justify-content-center " id='materiel'>
            <h2 className='titleFont' >Curso</h2>
            <hr />
            <ClasePRCompnent />
        </div>
    );
};

export default ClasesPR;
