// Render Prop
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { RiEye2Line, RiEyeCloseLine } from 'react-icons/ri';
import { AiOutlineMail } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { startWithEmailAndPassword } from '../actions/auth';
import { validarEmail } from './utils/emailValidation';
import { Button, Modal, Toast } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ModalComponent from './Modals/index.modals';
import RecoverFormComponent from '../Screens/login_page/components/recover-form.components';
const LoginComponent = () => {
    const dispatch = useDispatch();
    const handleLogin = (email, password) => {
        dispatch(startWithEmailAndPassword(email, password));
    }
    const [toggleModal, setToggleModal] = useState(false);
    const [seePassword, setSeePassword] = useState(false);
    return (
        <>


            <div>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleLogin(values.email, values.password);
                        setSubmitting(false);
                    }}
                    validate={validarEmail}
                >
                    {({ isSubmitting }) => (

                        <Form className='row justify-content-center form-container' >
                            <h3 className='text-center mb-5 text-light'>Bienvenido!</h3>
                            <div className="col-12  inputLabel-container">
                                <label htmlFor="email" className='text-light text-start'>Tu correo</label>
                                <div className="inputIcon-container">
                                    <span className='form-icon'><AiOutlineMail /></span>
                                    <Field name="email" type="email" id="email" className="form-input" placeholder="email" />
                                </div>
                            </div>
                            <p className='error-message mb-5' >
                                <ErrorMessage name="email" />
                            </p>
                            <div className="col-12 inputLabel-container">
                                <label htmlFor="email" className="text-start text-light" >Tu Contraseña</label>
                                <div className='inputIcon-container'>
                                    <div className="form-icon" onClick={() => setSeePassword(!seePassword)}>
                                        {seePassword ? <RiEye2Line /> : <RiEyeCloseLine />}
                                    </div>
                                    <Field name="password" placeholder="Contraseña" type={seePassword ? "text" : "password"} id="password" className="form-input" />
                                </div>
                            </div>
                            <p className='error-message' >
                                <ErrorMessage name="password" className='error-message' />
                            </p>
                            <button className='col-4  text-center col-md-4 btn btn-dark fs-5' type="submit" disabled={isSubmitting}>Ingresar</button>
                            <Button variant="link" className='col-12 text-center' type="button" onClick={
                                () => setToggleModal(true)}
                            >¿Olvidaste la contraseña?</Button>
                        </Form>)}
                </Formik>
            </div>
            <ModalComponent setShow={setToggleModal} show={toggleModal}  title="Recuperar contraseña"/>
        </>
    )
};

export default LoginComponent;
