import React, { useEffect } from 'react'
import { autoScroll } from '../../../utils/Autoscroll';

const Gramatica = () => {
    useEffect(() => {
        console.log("me imprimo");
        autoScroll(0,700)
        return () => {
            
        }
    }, [])

    return (
        <div className='container'>
            <h2 className='titleFont'>Gramatica</h2>
            <hr />
            <h3>
                Teoria
            </h3>
            <div className='container d-flex justify-content-evenly text-center'>
                <div className="row">
                    <div className="col-sm">
                        <button className="button"><a href="#"  >topic</a></button>
                    </div>
                    <div className="col-sm">
                        <button className="button"><a href="#"  >topic</a></button>
                    </div>
                    <div className="col-sm">
                        <button className="button"><a href="#"  >topic</a></button>
                    </div>
                    <div className="col-sm">
                        <button className="button"><a href="#"  >topic</a></button>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default Gramatica;
