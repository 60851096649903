import React from "react";
import { Ratio } from "react-bootstrap";

export const SideToSide = ({ embed, dataHome = {}, leftToRight = true, Mark = true }) => {

    return (
        <section className="col mainClass__body">
            {leftToRight ?
                <>
                    {
                        Mark ?
                            <>
                                <div className="body__embed rounded" style={{ height: "auto" }}>
                                    <Ratio aspectRatio="16x9">
                                        <embed src={embed} />
                                    </Ratio>
                                </div>
                            </>
                            :
                            <Ratio aspectRatio="16x9">
                                <embed src={embed} />
                            </Ratio>
                    }
                    <div className="body__title">
                        <h2 className="titleFont">{dataHome.title}</h2>
                        <p>{dataHome.value}</p>
                    </div>
                </>
                :
                <>
                    <div className="body__title">
                        <h2 className="titleFont">{dataHome.title}</h2>
                        <p>{dataHome.value}</p>
                    </div>
                    {
                        Mark ?
                            <>
                                <div className="body__embed" style={{ height: "auto" }}>
                                    <Ratio aspectRatio="16x9">
                                        <embed src={embed} />
                                    </Ratio>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ height: "auto", width: "25em" }}>
                                    <Ratio aspectRatio="1x1">
                                        <embed src={embed} />
                                    </Ratio>
                                </div>

                            </>
                    }
                </>
            }
        </section>
    );
};
