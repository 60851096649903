const lespace = [{
    questionText: "1- Combien de cosmonautes vont décoller ?",
    answerOptions: [{
        answerText: "3",
        isCorrect: true
    },
    {
        answerText: "4",
        isCorrect: false
    },
    {
        answerText: "5",
        isCorrect: false
    },
    ]
},
{
    questionText: "2- Où vont-ils ?",
    answerOptions: [{
        answerText: "sur la Lune",
        isCorrect: false
    },

    {
        answerText: "sur Mars",
        isCorrect: false
    },
    {
        answerText: "dans la station spatiale internationale",
        isCorrect: true
    },
    ]
},
{
    questionText: "3- Où se situe la station spatiale internationale (SPI) ?",
    answerOptions: [{
        answerText: " à 300 kilomètres de la terre.",
        isCorrect: false
    },
    {
        answerText: "à 400 kilomètres de la terre.",
        isCorrect: true
    },
    {
        answerText: "à 500 kilomètres de la terre.",
        isCorrect: false
    },

    ]
},
{
    questionText: "4- Combien de temps les cosmonautes vont-ils passer dans la SPI ?",
    answerOptions: [{
        answerText: "2 mois",
        isCorrect: false
    },
    {
        answerText: "4 mois",
        isCorrect: false
    },
    {
        answerText: "6 mois",
        isCorrect: true
    },
    ]
},
{
    questionText: "5- De quelle nationalité est Tomas Pesquet ?",
    answerOptions: [{
        answerText: "Russe",
        isCorrect: false
    },
    {
        answerText: "Française",
        isCorrect: true
    },

    {
        answerText: "américaine",
        isCorrect: false
    },
    ]
},
{
    questionText: "6- Le correspondant de RFI ne peut pas s’approcher de la fusée pour le décollage à cause des flammes.",
    answerOptions: [{
        answerText: "Vrai",
        isCorrect: true
    },
    {
        answerText: "Faux",
        isCorrect: false
    },
    ]
},

]

export {
    lespace
};