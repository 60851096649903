const questions = [
    {
        questionText: "Edwin, s’il vous plaît, ______ au téléphone pour moi.",
        answerOptions: [
            {
                answerText: "répondez", isCorrect: true
            },
            { answerText: "réponds", isCorrect: false },
            { answerText: "répondre", isCorrect: false },
            { answerText: "répond", isCorrect: false },
        ]
    },
    {
        questionText: "Si Pedro avait pu prendre le train de midi, il ______ à temps pour le cours de français ce soir.",
        answerOptions: [
            { answerText: "aurait arrivé", isCorrect: false },
            { answerText: "arriverait", isCorrect: false },
            { answerText: "serait arrivé", isCorrect: true },
            { answerText: "est arrivé", isCorrect: false },
        ]
    },
    {
        questionText: "En ______ à sa leçon de danse en taxi, Marta a perdu son portefeuille.",
        answerOptions: [
            { answerText: "allait", isCorrect: false },
            { answerText: "allant", isCorrect: true },
            { answerText: "allante", isCorrect: false },
            { answerText: "aller", isCorrect: false },
        ]
    },
    {
        questionText: "Tatiana ______ deux jolis enfants",
        answerOptions: [
            { answerText: "avoir", isCorrect: false },
            { answerText: "a", isCorrect: true },
            { answerText: "as", isCorrect: false },
            { answerText: "ai", isCorrect: false },
        ]
    },
    {
        questionText: "Juan est très occupé ces jours-ci; cela m’étonnerait qu’il ______ demain",
        answerOptions: [
            { answerText: "venira", isCorrect: false },
            { answerText: "vient", isCorrect: false },
            { answerText: "viendra", isCorrect: false },
            { answerText: "vienne", isCorrect: true },
        ]
    },
    {
        questionText: "David ira chercher Astrid à la gare et ______ ramènera à la maison.",
        answerOptions: [
            { answerText: "elle", isCorrect: false },
            { answerText: "le", isCorrect: false },
            { answerText: "lui", isCorrect: false },
            { answerText: "la", isCorrect: true },
        ]
    },
    {
        questionText: "J’aime beaucoup ______ fleurs.",
        answerOptions: [
            { answerText: "les", isCorrect: true },
            { answerText: "l’", isCorrect: false },
            { answerText: "la", isCorrect: false },
            { answerText: "le", isCorrect: false },
        ]
    },
    {
        questionText: "Tu veux que je ______ plus rapide, mais je ne peux pas aller plus vite!",
        answerOptions: [
            { answerText: "suis", isCorrect: false },
            { answerText: "sois", isCorrect: true},
            { answerText: "suis", isCorrect: false },
            { answerText: "soie", isCorrect: false },
        ]
    },
    {
        questionText: "Si mon père était en meilleure santé, il ______ me voir ici aux Etats-Unis.",
        answerOptions: [
            { answerText: "viendra", isCorrect: false },
            { answerText: "venait", isCorrect: false },
            { answerText: "vient", isCorrect: false },
            { answerText: "viendrait", isCorrect: true },
        ]
    },
    {
        questionText: "Nous ______ très contents de vous voir.",
        answerOptions: [
            { answerText: "sont", isCorrect: false },
            { answerText: "suis", isCorrect: false },
            { answerText: "est", isCorrect: false },
            { answerText: "sommes", isCorrect: true },
        ]
    }
]

export {questions};