const Brachetti = [{
    questionText: "Elisa a vu le spectacle d'Arturo Brachetti",
    answerOptions: [
        {
            answerText: "il y a une semaine",
            isCorrect: true
        },
        {
            answerText: "il y a un mois",
            isCorrect: false
        },
        {
            answerText: "il y a un an",
            isCorrect: false
        },
    ]
},
{
    questionText: "Combien de représentations y a-t-il eu à Paris ?",
    answerOptions: [{
        answerText: "30",
        isCorrect: false
    },

    {
        answerText: "40",
        isCorrect: true
    },
    {
        answerText: "80",
        isCorrect: false
    },
    ]
},
{
    questionText: "Elisa a beaucoup aimé le spectacle",
    answerOptions: [{
        answerText: "Vrai",
        isCorrect: true
    },
    {
        answerText: "Faux",
        isCorrect: false
    },
    ]
},
{
    questionText: "Arturo Brachetti est seul sur scène",
    answerOptions: [{
        answerText: "Vrai",
        isCorrect: false
    },
    {
        answerText: "Faux",
        isCorrect: true
    },

    ]
},
{
    questionText: "Il est connu pour",
    answerOptions: [{
        answerText: "parler 26 langues différentes",
        isCorrect: false
    },
    {
        answerText: "changer de tenue très rapidement",
        isCorrect: true
    },


    ]
},
{
    questionText: "C'est un artiste",
    answerOptions: [
        {
            answerText: "espagnol",
            isCorrect: false
        },
        {
            answerText: "Français",
            isCorrect: false
        },
        {
            answerText: "italien",
            isCorrect: true
        },
    ]
},

]

export {
    Brachetti
};