import React from 'react'
import CalificarEn from '../../../../Components/CalificarEN'



const RubricEnA1 = () => {
    return (
        <div style= {{marginTop: "2em"}}>
            <CalificarEn />
        </div>
    )
}

export default RubricEnA1
