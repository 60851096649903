import React, { useEffect, useState } from "react";
import MaterialRoutes from "../../routes/materialRoutes";
import { useRouteMatch } from "react-router-dom";
import { UseCallStudentPromo } from "../../hooks/UseCallStudentPromo";
import { BiLogIn } from "react-icons/bi";
import { useSelector } from "react-redux";

export default function Material() {
  const auth = useSelector((state) => state.auth);

  let { path, url } = useRouteMatch();

  const { handleLogout } = UseCallStudentPromo();
  return (
    <>
      <MaterialRoutes url={url} path={path} />
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "10%",
          }}
          className="button pointer"
          onClick={() => handleLogout()}
        >
          <span>Cerrar sesión</span>
          <div className="">
            <button className="btn p-0">
              <BiLogIn className="fs-4" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
