import { Link, NavLink } from "react-router-dom";
import MoldeImg from "../molde-img/molde-img";
import logo from "./Logo.png";
import React, { useState } from "react";
import { BiLogIn } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { startLogout } from "../../actions/auth";
import './animation.css';
export default function Header() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const handleLogout = () => {
    dispatch(startLogout());
  }

  const [hamb, setHamb] = useState(false);
  return (
    <div className="header-container" style={{ position: "fixed" }}>
      <Link to="/">
        <img
          src={logo}
          alt="Logotipo en naranja"
          className="logotipo-principal"
        />
      </Link>

      <div className="header-container__logoandFlags">
        <MoldeImg pais="guatemala" />
        <MoldeImg pais="nicaragua" />
        <MoldeImg pais="honduras" />
        <MoldeImg pais="el salvador" />
      </div>
      <section>
        <div
          id="nav-icon1"
          className={hamb ? "open" : ""}
          onClick={() => setHamb(!hamb)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div
          style={{ display: hamb ? "block" : "none" }}
          className="hiddenElement"
        >
          <ul>
            <li className="unordened__element">
              <NavLink exact to="/" activeClassName="activeList" onClick={() => setHamb(false)}>
                Inicio
              </NavLink>
            </li>
            <li className="unordened__element">
              <NavLink exact to="/Nosotros" activeClassName="activeList" onClick={() => setHamb(false)}>
                Nosotros
              </NavLink>
            </li>
            <li className="unordened__element">
              <NavLink exact to="/Material" activeClassName="activeList" onClick={() => setHamb(false)}>
                Estudiantes
              </NavLink>
            </li>
            {auth.rol === "profesor" && <li className="unordened__element">
              <NavLink exact to="/Teachers" activeClassName="activeList">
                Maestros
              </NavLink>
            </li>}
              <li className="unordened__element unordened__element--button">
                <a
                  href="https://m.me/GoGo.Academy.sv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </a>
              </li>
            <li className="unordened__element unordened__element--button">
              <a
                href="https://form.jotform.com/232450687248866"
                target="_blank"
                rel="noopener noreferrer"
              >
                Referidos
              </a>
            </li>
            <li className="unordened__element unordened__element--button">
              <a
                href="https://forms.gle/8JKTYzpZSwT78Kg26"
                target="_blank"
                rel="noopener noreferrer"
              >
              Cotización
              </a>
            </li>
            {
              auth.uid &&
              <li className="unordened__element">
                <button className="btn p-0" onClick={() => handleLogout()}>
                  Cerrar sesión
                  <BiLogIn className="fs-4" />
                </button>
              </li>}

          </ul>
        </div>
      </section>
      <nav className="header-container__navigator">
        <ul className="navigator__unordened flex-wrap">
          <li className="unordened__element">
            <NavLink exact to="/" activeClassName="activeList">
              Inicio
            </NavLink>
          </li>
          <li className="unordened__element">
            <NavLink exact to="/Nosotros" activeClassName="activeList">
              Nosotros
            </NavLink>
          </li>
          <li className="unordened__element">
            <NavLink exact to="/Material" activeClassName="activeList">
              Estudiantes
            </NavLink>
          </li>
          {auth.rol === "profesor" && <li className="unordened__element">
            <NavLink exact to="/Teachers" activeClassName="activeList">
              Maestros
            </NavLink>
          </li>}
          {/* {
            !auth.uid &&
            <li className="unordened__element unordened__element--button shadow-drop-2-tb">
              <a
                href="https://m.me/GoGo.Academy.sv"
                target="_blank"
                rel="noopener noreferrer"
              >
                Inscríbete
              </a>
            </li>
          } */}
          <li className="unordened__element unordened__element--button vibrate-3">
            <a
              href="https://form.jotform.com/232450687248866"
              target="_blank"
              rel="noopener noreferrer"
            >
              Referidos
            </a>
          </li>
          <li className="unordened__element unordened__element--button bounce-top">
              <a
                href="https://forms.gle/8JKTYzpZSwT78Kg26"
                target="_blank"
                rel="noopener noreferrer"
              >
              Cotización
              </a>
            </li>
        </ul>
      </nav>
    </div>

  );
}
