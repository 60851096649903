import React from 'react'
import { Link } from 'react-router-dom'
import Buttonss from './UI/Buttonss'

export const StudentStage = ({ url, level }) => {
    return (
        <>
            <div className="section__container--buttons">

                <div className="boton_up">
                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_ALTEREGOA} >
                        Livres Alter Ego +Audios
                    </Buttonss>
                </div>
                <div className="boton_up">
                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_DELF}>
                        Le DELF
                    </Buttonss>
                </div>
                <div className="boton_down">
                    <Buttonss type="button" className="button"> <Link to={`${url}/clases`}>
                        Matériel de cours
                    </Link> </Buttonss>
                </div>
                <div className="boton_up">
                    <Buttonss type="button" className="button just" isLink url={process.env.REACT_APP_DRIVE_CLUB}>
                        Matériel du club de conversation
                    </Buttonss>
                </div>
            </div>
            <hr />
            {/* <h3 className="text-center">Material:</h3> */}
            <div className="section__container--buttons">
                <div className="boton_up">

                    <Buttonss type="button" className="button "><Link to={`${url}/lectura`}>Lecture</Link> </Buttonss>
                    <Buttonss type="button" className="button"><Link to={`${url}/pronunciacion`} >Pronunciation</Link></Buttonss>
                </div>
                <div className="boton_down">
                    <Buttonss type="button" className="button" isLink url={process.env.REACT_APP_DRIVE_PHONETIQUE}> Phonétique</Buttonss>
                    <Buttonss type="button" className="button"> <Link to={`${url}/comprension-oral`}>Compréhension</Link> </Buttonss>
                </div>
            </div>
        </>)
}
