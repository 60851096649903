export const types = {
    login: "[AUTH] Login",
    logout: "[AUTH] Logout",
    logsetLevelAndRol: "[AUTH] set level and rol",
    books: "[GETDOC] books",
    cuentos: "[GETDOC] cuentos",
    calificarChooseQuestionOne: "[CALIFICAR] answer one selected",
    calificarChooseQuestionTwo: "[CALIFICAR] answer Two selected",
    calificarChooseQuestionThree: "[CALIFICAR] answer Three selected",
    calificarChooseQuestionFour: "[CALIFICAR] answer Four selected",
    listarBlog: "[BLOG ACTION] mostrar lista de blogs",
    loadVideos: "[VIDEOS ACTION] cargar videos",
    setCurrentLanguage: "[AUTH] change language",
    setLanguageAndstatus: "[AUTH] set languageInfo and status"
}