const spectateurs = [{
    questionText: "1. En France, le 14 juillet est la date de :",
    answerOptions: [{
        answerText: "la fête nationale",
        isCorrect: true
    },
    {
        answerText: "la fête de la musique.",
        isCorrect: false
    },
    {
        answerText: "la fête du Travail.",
        isCorrect: false
    },
    ]
},
{
    questionText: "'2. Parmi les événements de ce jour de fête, le journaliste évoque :",
    answerOptions: [{
        answerText: "les feux d'artifice et un défilé militaire.",
        isCorrect: true
    },

    {
        answerText: "un concert et  des manifestations.",
        isCorrect: false
    },
    ]
},
{
    questionText: "3. En 2020, à cause de la pandémie de Covid-19 :",
    answerOptions: [{
        answerText: "Le défilé militaire n'a pas eu lieu.",
        isCorrect: true
    },
    {
        answerText: "Le défilé militaire a eu lieu sans spectateurs.",
        isCorrect: false
    },

    ]
},
{
    questionText: "4. Cette année, combien de spectateurs ont pu assister au défilé ?",
    answerOptions: [{
        answerText: "5 000",
        isCorrect: false
    },
    {
        answerText: "10 000",
        isCorrect: true
    },
    {
        answerText: "25 000",
        isCorrect: false
    },
    ]
},
{
    questionText: "5. Ce défilé a lieu à Paris :",
    answerOptions: [{
        answerText: "sur la place de la Concorde.",
        isCorrect: false
    },
    {
        answerText: "sur les Champs-Élysées.",
        isCorrect: true
    },

    {
        answerText: "au Louvre.",
        isCorrect: false
    },
    ]
},
{
    questionText: "6. Le défilé est ouvert par une salutation :",
    answerOptions: [{
        answerText: "du président de la République.",
        isCorrect: true
    },
    {
        answerText: "du Premier ministre.",
        isCorrect: false
    },

    {
        answerText: "de la ministre des Armées.",
        isCorrect: false
    },
    ]
},

]

export {
    spectateurs
};