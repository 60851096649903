const fredericParle = [{
    questionText: "Frédéric vient de",
    answerOptions: [{
        answerText: "Laval",
        isCorrect: false
    },
    {
        answerText: "Montréal",
        isCorrect: true
    },
    {
        answerText: "Québec",
        isCorrect: false
    },
    ]
},
{
    questionText: "Il n'est jamais allé à Paris",
    answerOptions: [{
        answerText: "Vrai",
        isCorrect: false
    },

    {
        answerText: "Faux",
        isCorrect: true
    },

    ]
},
{
    questionText: "Quelle résolution ne mentionne-t-il pas ?",
    answerOptions: [{
        answerText: "Perdre du poids",
        isCorrect: false
    },
    {
        answerText: "Faire de l'exercice",
        isCorrect: false
    },
    {
        answerText: "Mieux manger",
        isCorrect: false
    },
    {
        answerText: "Apprendre l'espagnol",
        isCorrect: true
    },
    ]
},
{
    questionText: "Pour préparer son repas, Frédéric va",
    answerOptions: [{
        answerText: "au marché",
        isCorrect: false
    },
    {
        answerText: "au supermarché",
        isCorrect: true
    },
    ]
},
{
    questionText: "Au menu, il y aura",
    answerOptions: [{
        answerText: "un bœuf Wellington et une bouteille de Champagne",
        isCorrect: true
    },
    {
        answerText: "une dinde farcie et une bouteille de Champagne",
        isCorrect: false
    },
    {
        answerText: "des huîtres, du saumon fumé et une bouteille de Champagne",
        isCorrect: false
    },

    ]
},
{
    questionText: ".Quand Frédéric parle, quel jour sommes-nous ?",
    answerOptions: [{
        answerText: "Le 30 décembre",
        isCorrect: false
    },
    {
        answerText: "Le 31 décembre",
        isCorrect: true
    },

    {
        answerText: "Le 1er janvie",
        isCorrect: false
    },
    {
        answerText: "On ne sait pas",
        isCorrect: false
    },
    ]
},


]

export {
    fredericParle
};