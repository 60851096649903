import bruno from '../../../audios/bruno.mp3';
import valeria from '../../../audios/valerie_presenter.mp3';
import laMinuteActuAudio from '../../../audios/laMinuteActu.mp3';
import {
    brunoQuiz
} from './brunoQuiz';
import { laMinuteActu } from './laMinuteActu';
import {
    valeriaQuiz
} from './valeriaQuiz';

export const oralBasic = [{
    title: "La maison de Bruno",
    data: {
        audio: bruno,
        questions: brunoQuiz,
        credit: "https://audio-lingua.eu/spip.php?article3374&lang=fr",
        creditText: "Source audio : Audio-Lingua - Bruno (Clamart, France) - Ma maison",
        transcription: [
            `J'habite dans une maison qui a trois étages plus un grenier et un sous-sol, au rez-de-chaussée il y a donc une cuisine, un salon, une salle à manger, un escalier qui monte en partant sur la droite et dessert le premier étage où se trouvent un bureau, deux chambres, une salle de bains.`,
            `À l'étage d'en haut, il y a deux autres chambres, les chambres de mes enfants, et une salle qui pour l'instant n'est pas encore une salle de jeux mais qui le sera peut-être, ou alors une salle de musique, ou alors peut-être une salle pour écouter de la musique, regarder la télévision, et il y a un balcon aussi depuis lequel nous pouvons apercevoir la tour Eiffel.`
        ]
    }
},
{
    title: "Valérie se présente",
    data: {
        audio: valeria,
        questions: valeriaQuiz,
        credit: "https://audio-lingua.eu/spip.php?article566&lang=fr",
        creditText: "Source audio : Audio-Lingua - Valérie (Québec) - Valérie se présente",
        transcription: [
            `Bonjour, alors je m'appelle Valérie, je viens du Québec, je suis née dans le petit village de Maria en Gaspésie, c'est à l'est de la Province du Québec, j'ai vingt-trois ans, je suis étudiante à l'université Laval qui est dans la Ville de Québec qui est la capitale de la province, je suis en troisième année d'université, je suis présentement en France pour la session d'hiver, je fais un stage au MLK jusqu'à la fin mars.`,
            `Chez moi je suis enfant unique donc je n'ai pas de frère ni de sœur, mon père s'appelle Camille et ma mère s'appelle Louise. J'avais un chat qui s'appelait Passe-poil mais il est décédé il y a quelques années, et voilà, je suis bien heureuse d'être ici et je passe du bon temps en France et j'aime beaucoup l'expérience au MLK.`
        ]
    }
},
{
    title: `La minute actu : 
        manifestations en France`,
    data: {
        audio: laMinuteActuAudio,
        questions: laMinuteActu,
        credit: null,
        creditText: `Extrait du Journal en français facile du 13/01/2022
        Rédactrice : Delphine Ripaud
        `,
        transcription: [
            `Clémentine Pawlotsky : Journée de forte mobilisation, donc, en France. Plusieurs dizaines de milliers d'enseignants et personnels de l'Éducation nationale étaient en grève aujourd'hui pour protester contre le protocole sanitaire anti-Covid-19, mis en place par les autorités, dans les établissements.`,
            `Des manifestations ont eu lieu un peu partout dans le pays. C'était par exemple le cas à Marseille, grande ville du sud de la France, où un cortège de 2 200 personnes a défilé, selon les chiffres de la police. Des manifestants venus dire leur ras-le-bol. `
        ]
    }
},

]
