import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Buttonss from '../../../../Components/UI/Buttonss';
const ClasePRCompnent = () => {
    const auth = useSelector(state => state.auth);
    const handleShowAlert = () => {
        return Swal.fire(
            "Acceso denegado",
            "Aun no has desbloqueado este nivel",
            "info"
        )
    }

    const handleShowAlertUp = () => {
        return Swal.fire("", "Ya has completado este nivel", "info")
    }
    return (
        <>
            <div className="container d-flex justify-content-evenly text-center">
                <div className="row">
                    {auth.rol === "estudiante" && auth.level === "A1" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERSBASIC_PR} className="pointer" target="_blank" rel="noopener noreferrer">Básico (corrente)</a>
                                </Buttonss>

                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Intermediário
                                </Buttonss>

                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Avançado
                                </Buttonss>
                            </div>
                            {/* <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Renforcement (optionnel)
                                </Buttonss>
                            </div> */}
                        </>
                    }
                    {auth.rol === "estudiante" && auth.level === "A2" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Basic (exceeded)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERINTERMEDIATE_PR} className="pointer" target="_blank" rel="noopener noreferrer">Intermediário (corrente)</a>
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Avançado
                                </Buttonss>
                            </div>
                            {/* <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Renforcement (optionnel)
                                </Buttonss>
                            </div> */}


                        </>
                    }
                    {auth.rol === "estudiante" && auth.level === "B1" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Basic (aprovado )
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Intermediate (aprovado )
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDONE_PR} className=" pointer" target="_blank" rel="noopener noreferrer">Avançado (corrente)</a>
                                </Buttonss>
                            </div>
                            {/* <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Renforcement (optionnel)
                                </Buttonss>
                            </div> */}
                        </>
                    }
                    {auth.rol === "estudiante" && auth.level === "B2" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                Básico (aprovado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                Intermediário (aprovado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                Avançado (aprovado)
                                </Buttonss>
                            </div>
                            {/* <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDTWO} className="pointer" target="_blank" rel="noopener noreferrer"> Renforcement (optionnel)</a>
                                </Buttonss>
                            </div> */}
                        </>
                    }

                    {auth.rol === "profesor" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERSBASIC_PR} className="pointer" target="_blank" rel="noopener noreferrer">Básico </a>
                                </Buttonss>

                            </div>

                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERINTERMEDIATE_PR} className="pointer" target="_blank" rel="noopener noreferrer">Intermediário </a>
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDONE_PR} className=" pointer" target="_blank" rel="noopener noreferrer">Avançado </a>
                                </Buttonss>
                            </div>
                            {/* <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDTWO} className="pointer" target="_blank" rel="noopener noreferrer"> Renforcement (optionnel)</a>
                                </Buttonss>
                            </div> */}
                        </>
                    }

                </div>
            </div>
            <hr />

        </>
    );
};

export default ClasePRCompnent;
