const cuentos = `${process.env.REACT_APP_FIREBASE_CUENTOS}`;
const libros = `${process.env.REACT_APP_FIREBASE_BOOKS_DIRECTION}`;
const promo = `${process.env.REACT_APP_FIREBASE_PROMO}`;
const videos = `${process.env.REACT_APP_FIREBASE_VIDEOS}`;
const notificacionStudent = `${process.env.REACT_APP_FIREBASE_NOTIFICATION_USER}`;
export const getCuentos =()=> `cuentos/${cuentos}`;

export const getLibros = ()=>`libros/${libros}`;

export const getPromo = ()=> `promos/${promo}`;

export const getNofiticationStudents = ()=> `notificationStudent/${notificacionStudent}`


export const getVideos = ()=>  `videos/${videos}`