import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Buttonss from './UI/Buttonss';
const ClasesComponent = () => {
    const auth = useSelector(state => state.auth);
    const handleShowAlert = () => {
        return Swal.fire(
            "Acceso denegado",
            "Aun no has desbloqueado este nivel",
            "info"
        )
    }

    const handleShowAlertUp = () => {
        return Swal.fire("", "Ya has completado este nivel", "info")
    }
    return (
        <>
            <div className="container d-flex justify-content-evenly text-center">
                <div className="row">
                    {auth.rol === "estudiante" && auth.level === "A1" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERSBASIC} className="pointer" target="_blank" rel="noopener noreferrer">Débutant (Actual)</a>
                                </Buttonss>

                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Intermédiaire
                                </Buttonss>

                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Avancé I
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Renforcement (optionnel)
                                </Buttonss>
                            </div>
                        </>
                    }
                    {auth.rol === "estudiante" && auth.level === "A2" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Débutant (Superado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERINTERMEDIATE} className="pointer" target="_blank" rel="noopener noreferrer">Intermédiaire (Actual)</a>
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Avancé I
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Renforcement (optionnel)
                                </Buttonss>
                            </div>


                        </>
                    }
                    {auth.rol === "estudiante" && auth.level === "B1" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Débutant (Superado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Intermédiaire (Superado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDONE} className=" pointer" target="_blank" rel="noopener noreferrer">Avancé I (Actual)</a>
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlert()}>
                                    Renforcement (optionnel)
                                </Buttonss>
                            </div>
                        </>
                    }
                    {auth.rol === "estudiante" && auth.level === "B2" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Débutant (Superado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Intermédiaire (Superado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button" onClicks={() => handleShowAlertUp()}>
                                    Avancé I (Superado)
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDTWO} className="pointer" target="_blank" rel="noopener noreferrer"> Renforcement (optionnel)</a>
                                </Buttonss>
                            </div>
                        </>
                    }

                    {auth.rol === "profesor" &&
                        <>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERSBASIC} className="pointer" target="_blank" rel="noopener noreferrer">Débutant </a>
                                </Buttonss>

                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERINTERMEDIATE} className="pointer" target="_blank" rel="noopener noreferrer">Intermédiaire </a>
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDONE} className=" pointer" target="_blank" rel="noopener noreferrer">Avancé I </a>
                                </Buttonss>
                            </div>
                            <div className="col-sm">
                                <Buttonss className="button">
                                    <a href={process.env.REACT_APP_DRIVE_FOLDERADVANCEDTWO} className="pointer" target="_blank" rel="noopener noreferrer"> Renforcement (optionnel)</a>
                                </Buttonss>
                            </div>
                        </>

                    }

                </div>
            </div>
            <hr />

        </>
    );
};

export default ClasesComponent;
