import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import AuthReducer from '../reducers/AuthReducer';
import BlogReducer from '../reducers/BlogReducer';
import { VideosReducer } from '../reducers/VideosReducer';

const reducers = combineReducers({
    auth: AuthReducer,
    blog: BlogReducer,
    videos: VideosReducer
})

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)