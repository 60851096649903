import React, {
    useState
} from 'react'

import {
    getDoc,
    doc
} from "firebase/firestore";
import {
    firestorage
} from '../firebase/firebaseConfig';
import { useSelector } from 'react-redux';


export const UseFetch = () => {
    const auth = useSelector(state => state.auth)
    const initialState = {
        code: null,
        message: null,
        isSuccess: false,
        isError: false,
        isLoading: false,
        response: null
    }
    const [response, setRequest] = useState(initialState);


    const request = async (url, method = "GET", body) => {
        const peticion = await fetch(url, method, body);
        // const thing = await 
    }

    const requestDocFromFirebase = async (url) => {
        try {
            const docRef = doc(firestorage, url);
            setRequest({
                ...response,
                isLoading: true
            })
            const document = await getDoc(docRef);
            if (document.exists()) {
                // console.log(document);
                const data = document.data();
                setRequest({
                    ...response,
                    isSuccess: true,
                    code: "00",
                    isError: false,
                    isLoading: false,
                    message:"exito",
                    response: data
                })
            } else {
                setRequest({
                    isSuccess: false,
                    code: "404",
                    isError: false,
                    isLoading: false,
                    message: "El documento no existe",
                    response: null
                })
            }
        } catch (e) {
                setRequest({
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                    message: e.message,
                    code: "400",
                    response: null
                })
        }
    }

    return [
        response,
        request,
        requestDocFromFirebase
    ];
}