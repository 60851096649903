import React, { useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import BookComponent from "../../../Components/BookComponent";
import { LecturaContext } from "../../../lecturaProvider/LecturaProvider";
import { autoScroll } from "../../../utils/Autoscroll";

export const Lectura = () => {
    useEffect(() => {
        console.log("me imprimo");
        autoScroll(0,700)
        return () => {
            
        }
    }, [])

    const { books, cuentos } = useContext(LecturaContext);
    return (
        <div className='container d-flex flex-column justify-content-center text-center mb-5'>
            <h2 className='titleFont text-start'>Lecture</h2>
            <hr />
            <Accordion>
                <BookComponent title="Livres" eventKey="0" bookList={books} />
            </Accordion>
            <Accordion>

                <BookComponent title="Histoires" eventKey="0" bookList={cuentos} />
            </Accordion>
        </div>
    )
}
