import React, { useEffect } from "react";
import Main from "../../Components/MainInfo/Main";
import { UseShowAlert } from "../../hooks/useShowAlert";

export default function Home() {
    //funcionalidad de mostrar alerta de promocion
    const mostrarAlertaPromocion = UseShowAlert();

    return (
        <div>
            <Main />
        </div>
    );
}
