import React from 'react'
import { Ratio } from 'react-bootstrap';
const Testimonios = ({ embed }) => {
    return (
        <div>
            <section className="col mainClass__body ">
                <div className="body__embed rounded-3" style={{ height: "auto" }}>
                    <Ratio aspectRatio="16x9">
                        <embed src={embed} />
                    </Ratio>
                </div>
            </section>
        </div>
    )
}

export default Testimonios
