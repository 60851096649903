import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";

export default function OnlyTeachers({ component: Component, ...rest }) {
    // const auth = useAuth();
    const auth = useSelector(state => state.auth);
    const location = useLocation();
    return (
        <Route {...rest}>
            {auth.rol === "profesor" ? (
                <Component />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: location } }} />
            )}
        </Route>
    );
}

