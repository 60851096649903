const dataHome = {
  title: "La mejor forma de aprender un nuevo idioma",
  value:
    "Aprende o perfecciona tu francés completamente en línea, desde cualquier parte del mundo. Contamos con  horarios flexibles y maestros altamente capacitados. "
};

export const dataMantenimiento = {
  title: "Sitio web en mantenimiento",
  value: "Hola querido estudiante, en estos momentos nos encontramos realizando una mejora en el sitio web, si tienes dudas puedes consultar a administración académica de Go Go Academy"
}

const dataCarousel = [
  {
    id: 1,
    title: "1- Un club de conversación GRATIS",
    value:
      "Adicional a tus clases de francés, puedes practicar en nuestros clubs de conversación los sábados y domingos. ",
  },
  {
    id: 2,
    title: "2- Materiales GRATIS",
    value:
      "Los materiales didácticos están incluidos en tu mensualidad (Libros en formato PDF +  Audios, presentaciones, diccionarios, etc). ",
  },
  {
    id: 3,
    title: "3- Oportunidad de empleo ",
    value:
      "Al finalizar el nivel  avanzado de francés, Go Go Academy se compromete a apoyarte en el proceso de preparación para aplicar a un trabajo 100% en francés. Contamos con Alianzas con call centers como TELUS International El Salvador. ",
  },
  {
    id: 4,
    title: "4- Profesores altamente calificados. ",
    value:
      "Los profesores están altamente calificados y cuentan con años de experiencia en la enseñanza del idioma como lengua extranjera. ",
  },
];

const secondData = {
  title: "Acerca del curso",
  value: "Nuestro curso de francés está estructurado según el Marco Común Europeo de Referencia para las lenguas, A1, A2, B1, B2. Cada lección está diseñada de una manera interactiva y entretenida para que el estudiante aprenda, practique y mejore sus conocimientos"
}

const dataNosotros = {
  title: "Quienes somos?",
  value: "Go Go Academy es una academia de enseñanza del idioma Francés. Nacemos con el objetivo de contribuir al desarrollo educativo, cultural y económico de las personas que buscan el éxito a través del aprendizaje de un idioma extranjero. Con metodología rápida y eficaz dirigida a desarrollar las habilidades lingüísticas de los estudiantes. Garantizamos un servicio de alta calidad y estamos orientados a ayudarte hasta donde te propongas"
}
export { dataHome, dataCarousel, secondData, dataNosotros };
