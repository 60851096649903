import { types } from "../types/types";

const initialState = {
  email: null,
  name: null,
  displayName: null,
  uid: null,
  rol: null,
  level: null,
  languageInfo: [],
  status: null,
  currentLanguage: null,
  currentLevel: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        ...action.payload,
      };
    case types.logsetLevelAndRol:
      return {
        ...state,
        rol: action.payload.rol,
        level: action.payload.level,
      };
    case types.setCurrentLanguage:
      console.log(action.payload);
      return {
        ...state,
        currentLanguage: action.payload.currentLanguage ?? null,
        level: action.payload.currentLevel ?? null,
        rol: action.payload.rol ?? null,
      };
    case types.setLanguageAndstatus:
      return {
        ...state,
        languageInfo: action.payload.languageInfo,
        status: action.payload.status
      }
    case types.logout:
      return {};
    default:
      return state;
  }
};

export default AuthReducer;
