import React, { useEffect } from 'react';
import Routes from './routes/index'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { Provider } from 'react-redux';
import LecturaProvider from './lecturaProvider/LecturaProvider';
import { store } from './store/store';
import './sass/app.scss';
import 'animate.css';
import "react-datepicker/dist/react-datepicker.css";
function App() {

  console.log = ()=>{}
  console.error = ()=>{}
  return (
    <Provider store={store} >
      <LecturaProvider>
        <Routes />
      </LecturaProvider>
    </Provider>

  );
}

export default App;
