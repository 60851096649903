import  {
    useState
} from 'react'

export const useChangeStatusLvl = () => {
    const [activeLvl, setActiveLvl] = useState({
        lvl1: true,
        lvl2: false,
        lvl3: false,
        lvl4: false
    });

    const {
        lvl1,
        lvl2,
        lvl3,
        lvl4
    } = activeLvl;
    const changeStatusLvl = (nivel) => {
        if (nivel === "a1") {
            setActiveLvl({
                lvl1: true,
                lvl2: false,
                lvl3: false,
                lvl4: false
            });
        } else if (nivel === "a2") {
            setActiveLvl({
                lvl1: false,
                lvl2: true,
                lvl3: false,
                lvl4: false
            });
        } else if (nivel === "b1") {
            setActiveLvl({
                lvl1: false,
                lvl2: false,
                lvl3: true,
                lvl4: false
            });
        } else if (nivel === "b2") {
            setActiveLvl({
                lvl1: false,
                lvl2: false,
                lvl3: false,
                lvl4: true
            });
        }
    }

    const infoCourse = [{
        level: "a1",
        content: "Serás capaz de comprender y utilizar expresiones cotidianas de uso muy frecuente, así como, frases sencillas destinadas a satisfacer necesidades de tipo inmediato.",
        isActive: lvl1,
        id: 1
    },
    {
        level: "a2",
        content: "Podrás comprender frases y expresiones de uso frecuente relacionadas con áreas de experiencia que son especialmente relevantes para ti.",
        isActive: lvl2,
        clase: "right-text",
        id: 2
    },
    {
        level: "b1",
        content: "Podrás producir un discurso simple y coherente sobre temas familiares y áreas de interés. Podrás relatar un evento, una experiencia o un sueño, describir una esperanza o una meta y exponer brevemente las razones o explicaciones de un proyecto o idea.",
        isActive: lvl3,
        id: 3
    },
    {
        level: "b2",
        content: "Podrás comunicarte con un grado de espontaneidad y fluidez, como una conversación con un hablante nativo. Podrás hablar con claridad y en detalle sobre una amplia gama de temas, desde temas de actualidad hasta discutir los pros y los contras de diferentes posibilidades.",
        isActive: lvl4,
        clase: "right-text",
        id: 4
    }

    ]
    return {
        activeLvl,
        changeStatusLvl,
        infoCourse
    }
}