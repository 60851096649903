import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { dataCarousel } from "../utils/data";
export const Carrusl = () => {

 

  return (
    <div className="carouselContainer">
      <Carousel className="carousel">
        {dataCarousel.map((item) => {
          return (
            <Carousel.Item interval={3000} className="carousel__item" key= {item.id}>
            <div className="itemContainer">
              <h3 >{item.title}</h3>
              <p>{item.value}</p>
            </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
