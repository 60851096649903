import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, firestorage } from "../firebase/firebaseConfig";
import { posibleFirebaseErrors } from "../types/firebaseErrorTypes";
import { doc, getDoc } from "firebase/firestore";
import { types } from "../types/types";
import Swal from "sweetalert2";

export const login = (user) => {
  return {
    type: types.login,
    payload: user,
  };
};

export const startLevelAndRol = (level, rol) => ({
  type: types.logsetLevelAndRol,
  payload: {
    level,
    rol,
  },
});
export const setCurrentLanguagec = (language) => ({
  type: types.setCurrentLanguage,
  payload: language,
});

export const setLanguageAndStatus = (data)=>({
  type: types.setLanguageAndstatus,
  payload: data
})

export const startWithEmailAndPassword = (email, password) => {
  return async (dispatch) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      const docuRef = doc(firestorage, `users/${user.uid}`);
      const docSnap = await getDoc(docuRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.table(data);
        dispatch(login(data));
        dispatch(setCurrentLanguagec({
          currentLanguage: data?.languageInfo[0]?.language ?? data.language,
          currentLevel: data?.languageInfo[0]?.level ?? data.level,
          rol: data?.languageInfo[0]?.rol ?? data.rol,
        }))
      }
    } catch (e) {
      switch (e.code) {
        case posibleFirebaseErrors.invalidPassword:
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Contraseña incorrecta",
          });
          break;
        case posibleFirebaseErrors.userNotFound:
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Usuario no encontrado",
          });
          break;
        default:
          return null;
      }
    }
  };
};

export const startLogout = () => {
  return (dispatch) => {
    signOut(auth);
    dispatch(logout());
  };
};

const logout = () => {
  return {
    type: types.logout,
  };
};
