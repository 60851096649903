import React from 'react'
import { types } from '../types/types';

const initialState = {
    list: [],
    active: {}
}

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.listarBlog:
            return {...state}
    
        default:
            return state
    }
}

export default BlogReducer
