import { useState } from "react";

export const useCalificarAdvanceState = () => {
    const [itemSelected, setItemSelected] = useState({
        one: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
        },
        two: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false,
            isActiveFive: false,
        },
        three: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false,
            isActiveFive: false,
        }
    });

    const [isDisabled, setIsDisabled] = useState({
        one: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
        },
        two: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false,
            isActiveFive: false,
        },
        three: {
            isActiveone: false,
            isActivetwo: false,
            isActiveThree: false,
            isActiveFour: false,
            isActiveFive: false
        }
    });

    const changeStateItem = (itemNumber, puntuacion) => {
        if (itemNumber === 1) {
            if (puntuacion === 0) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveone: !itemSelected.one.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveThree: itemSelected.one.isActiveone ? false : true,
                        isActiveone: !!isDisabled.one.isActiveone,
                        isActivetwo: itemSelected.one.isActiveone ? false : true,
                    },
                });
            }
            if (puntuacion === 0.5) {
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveone: false,
                        isActivetwo: !itemSelected.one.isActivetwo,
                        isActiveThree: false,
                    },
                });

                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveone: itemSelected.one.isActivetwo ? false : true,
                        isActivetwo: !!isDisabled.one.isActivetwo,
                        isActiveThree: itemSelected.one.isActivetwo ? false : true,
                    },
                });
            }
            if (puntuacion === 1) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    one: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.one.isActiveThree,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    one: {
                        isActiveone: itemSelected.one.isActiveThree ? false : true,
                        isActivetwo: itemSelected.one.isActiveThree ? false : true,
                        isActiveThree: !!isDisabled.one.isActiveThree,
                    },
                });
            }
        }
        if (itemNumber === 2) {
            if (puntuacion === 2) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                        isActiveFive: !itemSelected.two.isActiveFive,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveone: itemSelected.two.isActiveFive ? false : true,
                        isActivetwo: itemSelected.two.isActiveFive ? false : true,
                        isActiveThree: itemSelected.two.isActiveFive ? false : true,
                        isActiveFour: itemSelected.two.isActiveFive ? false : true,
                        isActiveFive: !!isDisabled.two.isActiveFive,
                    },
                });
            }
            if (puntuacion === 1.5) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: !itemSelected.two.isActiveFour,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveone: itemSelected.two.isActiveFour ? false : true,
                        isActivetwo: itemSelected.two.isActiveFour ? false : true,
                        isActiveThree: itemSelected.two.isActiveFour ? false : true,
                        isActiveFour: !!isDisabled.two.isActiveFour,
                        isActiveFive: itemSelected.two.isActiveFour ? false : true,
                    },
                });
            }
            if (puntuacion === 1) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.two.isActiveThree,
                        isActiveFour: false,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveone: itemSelected.two.isActiveThree ? false : true,
                        isActivetwo: itemSelected.two.isActiveThree ? false : true,
                        isActiveThree: !!isDisabled.two.isActiveThree,
                        isActiveFour: itemSelected.two.isActiveThree ? false : true,
                        isActiveFive: itemSelected.two.isActiveThree ? false : true,
                    },
                });
            }
            if (puntuacion === 0.5) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: false,
                        isActivetwo: !itemSelected.two.isActivetwo,
                        isActiveThree: false,
                        isActiveFour: false,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveone: itemSelected.two.isActivetwo ? false : true,
                        isActivetwo: !!isDisabled.two.isActivetwo,
                        isActiveThree: itemSelected.two.isActivetwo ? false : true,
                        isActiveFour: itemSelected.two.isActivetwo ? false : true,
                        isActiveFive: itemSelected.two.isActivetwo ? false : true,
                    },
                });
            }
            if (puntuacion === 0) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    two: {
                        isActiveone: !itemSelected.two.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    two: {
                        isActiveone: !!isDisabled.two.isActiveone,
                        isActivetwo: itemSelected.two.isActiveone ? false : true,
                        isActiveThree: itemSelected.two.isActiveone ? false : true,
                        isActiveFour: itemSelected.two.isActiveone ? false : true,
                        isActiveFive: itemSelected.two.isActiveone ? false : true,
                    },
                });
            }
        }
        if (itemNumber === 3) {
            if (puntuacion === 2) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                        isActiveFive: !itemSelected.three.isActiveFive,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveThree: itemSelected.three.isActiveFive ? false : true,
                        isActiveone: itemSelected.three.isActiveFive ? false : true,
                        isActivetwo: itemSelected.three.isActiveFive ? false : true,
                        isActiveFour: itemSelected.three.isActiveFive ? false : true,
                        isActiveFive: !!isDisabled.three.isActiveFive,
                    },
                });
            }
            if (puntuacion === 1.5) {
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: !itemSelected.three.isActiveFour,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveThree: itemSelected.three.isActiveFour ? false : true,
                        isActiveone: itemSelected.three.isActiveFour ? false : true,
                        isActivetwo: itemSelected.three.isActiveFour ? false : true,
                        isActiveFour: !!isDisabled.three.isActiveFour,
                        isActiveFive: itemSelected.three.isActiveFour ? false : true,
                    },
                });
            }
            if (puntuacion === 1) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: false,
                        isActiveThree: !itemSelected.three.isActiveThree,
                        isActiveFour: false,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveone: itemSelected.three.isActiveThree ? false : true,
                        isActivetwo: itemSelected.three.isActiveThree ? false : true,
                        isActiveThree: !!isDisabled.three.isActiveThree,
                        isActiveFour: itemSelected.three.isActiveThree ? false : true,
                        isActiveFive: itemSelected.three.isActiveThree ? false : true,
                    },
                });
            }
            if (puntuacion === 0.5) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: false,
                        isActivetwo: !itemSelected.three.isActivetwo,
                        isActiveThree: false,
                        isActiveFour: false,
                        isActiveFive: false,
                    },
                });

                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveone: itemSelected.three.isActivetwo ? false : true,
                        isActivetwo: !!isDisabled.three.isActivetwo,
                        isActiveThree: itemSelected.three.isActivetwo ? false : true,
                        isActiveFour: itemSelected.three.isActivetwo ? false : true,
                        isActiveFive: itemSelected.three.isActivetwo ? false : true,
                    },
                });
            }
            if (puntuacion === 0) {
                // if(itemSelected.one.isActiveFour)
                setItemSelected({
                    ...itemSelected,
                    three: {
                        isActiveone: !itemSelected.three.isActiveone,
                        isActivetwo: false,
                        isActiveThree: false,
                        isActiveFour: false,
                        isActiveFive: false,
                    },
                });
                setIsDisabled({
                    ...isDisabled,
                    three: {
                        isActiveone: !!isDisabled.three.isActiveone,
                        isActivetwo: itemSelected.three.isActiveone ? false : true,
                        isActiveThree: itemSelected.three.isActiveone ? false : true,
                        isActiveFour: itemSelected.three.isActiveone ? false : true,
                        isActiveFive: itemSelected.three.isActiveone ? false : true,
                    },
                });
            }
        }
    };

    const calificarInfo = {
        headers: ["Catégories", 4, 3, 2, 1],
        rows: [
            {
                categorie:
                    "Peut présenter un exposé structuré, comprenant une introduction, un développement et une conclusion.",
                title: null,
                
                options: [
                    {
                        itemNumber: 1,
                        text: "0",
                        puntuacion: 0,
                        isActive: itemSelected.one.isActiveone,
                        disabled: isDisabled.one.isActiveone,
                    },
                    {
                        itemNumber: 1,
                        text: "0,5",
                        puntuacion: 0.5,
                        isActive: itemSelected.one.isActivetwo,
                        disabled: isDisabled.one.isActivetwo,
                    },
                    {
                        itemNumber: 1,
                        text: "1",
                        puntuacion: 1,
                        isActive: itemSelected.one.isActiveThree,
                        disabled: isDisabled.one.isActiveThree,
                    },
                ],
            },
            {
                categorie:
                    "Peut développer des arguments clairs et pertinents, relie des idées par des connecteurs logiques variés et répond aux questions posées si c’est nécessaire. ",
                title: null,

                options: [
                    {
                        itemNumber: 2,
                        text: "0",
                        puntuacion: 0,
                        isActive: itemSelected.two.isActiveone,
                        disabled: isDisabled.two.isActiveone,
                    },
                    {
                        itemNumber: 2,
                        text: "0,5",
                        puntuacion: 0.5,
                        isActive: itemSelected.two.isActivetwo,
                        disabled: isDisabled.two.isActivetwo,
                    },
                    {
                        itemNumber: 2,
                        text: "1",
                        puntuacion: 1,
                        isActive: itemSelected.two.isActiveThree,
                        disabled: isDisabled.two.isActiveThree,
                    },
                    {
                        itemNumber: 2,
                        text: "1,5",
                        puntuacion: 1.5,
                        isActive: itemSelected.two.isActiveFour,
                        disabled: isDisabled.two.isActiveFour,
                    },
                    {
                        itemNumber: 2,
                        text: "2",
                        puntuacion: 2,
                        isActive: itemSelected.two.isActiveFive,
                        disabled: isDisabled.two.isActiveFive,
                    },
                ],
            },
            {
                categorie: " Respecte la longueur du temps pendant la présentation (donnée par votre prof de français) et prépare des matériaux (Google slide presentation, des photos, etc) du sujet pour montrer dans la classe.",
                title: null,

                options: [
                    {
                        itemNumber: 3,
                        text: "0",
                        puntuacion: 0,
                        isActive: itemSelected.three.isActiveone,
                        disabled: isDisabled.three.isActiveone,
                    },
                    {
                        itemNumber: 3,
                        text: "0.5",
                        puntuacion: 0.5,
                        isActive: itemSelected.three.isActivetwo,
                        disabled: isDisabled.three.isActivetwo,
                    },
                    {
                        itemNumber: 3,
                        text: "1",
                        puntuacion: 1,
                        isActive: itemSelected.three.isActiveThree,
                        disabled: isDisabled.three.isActiveThree,
                    },
                    {
                        itemNumber: 3,
                        text: "1.5",
                        puntuacion: 1.5,
                        isActive: itemSelected.three.isActiveFour,
                        disabled: isDisabled.three.isActiveFour,
                    },
                    {
                        itemNumber: 3,
                        text: "2",
                        puntuacion: 2,
                        isActive: itemSelected.three.isActiveFive,
                        disabled: isDisabled.three.isActiveFive,
                    },
                ],
            },
        ],
    };

    return {
        itemSelected,
        changeStateItem,
        calificarInfo,
    };
};
