const valeriaQuiz = [{
    questionText: "Valérie est née au Québec",
    answerOptions: [{
            answerText: "dans la région de Laval",
            isCorrect: false
        },
        {
            answerText: "dans la région de l'Outaouais",
            isCorrect: false
        },
        {
            answerText: "en Gaspésie",
            isCorrect: true
        },
    ]
},
{
    questionText: "Quel âge a-t-elle ?",
    answerOptions: [{
            answerText: "21 ans",
            isCorrect: false
        },

        {
            answerText: "23 ans",
            isCorrect: true
        },
        {
            answerText: "24 ans",
            isCorrect: false
        },
    ]
},
{
    questionText: "Pour quelle raison est-elle en France ?",
    answerOptions: [{
            answerText: "Elle fait du tourisme",
            isCorrect: false
        },
        {
            answerText: "Elle fait un stage pour ses études",
            isCorrect: true
        },
        {
            answerText: "Elle travaille",
            isCorrect: false
        },

    ]
},
{
    questionText: "Comment s'appelle sa mère ?",
    answerOptions: [{
            answerText: "Denise",
            isCorrect: false
        },
        {
            answerText: "Louise",
            isCorrect: true
        },

    ]
},
{
    questionText: "Elle a un chat",
    answerOptions: [{
            answerText: "Vrai",
            isCorrect: false
        },
        {
            answerText: "Faux",
            isCorrect: true
        },
    ]
},
{
    questionText: "Elle est très heureuse d'être en France",
    answerOptions: [{
            answerText: "Vrai",
            isCorrect: true
        },
        {
            answerText: "Faux",
            isCorrect: false
        },
    ]
},

]

export {
valeriaQuiz
};