import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import Swal from 'sweetalert2';
const QuizComponent = ({ questions }) => {
    let timer;
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const [yourAnswers, setyourAnswers] = useState([]);
    let correct2 = [];
    let corrects = [];

    const handleReset = () => {
        setCurrentQuestion(0);
        setShowScore(false);
        setScore(0);

        setyourAnswers([]);
    }


    questions.map(question => {
        question.answerOptions.map(item => {
            if (item.isCorrect) {
                correct2.push(item.answerText);
            }
        })
    })

    useEffect(() => {

        return () => {
            window.clearTimeout(timer);
        }
    }, [timer])


    questions[currentQuestion].answerOptions.map(item => {
        if (item.isCorrect) {
            corrects.push(item.answerText);
        }
    })

    const handleAnswerOptionClick = (answerOption) => {
        setyourAnswers([...yourAnswers, answerOption.answerText]);

        if (answerOption.isCorrect) {
            setScore(score + 1);
        }
        if (corrects.includes(answerOption.answerText)) {
            Swal.fire(
                'Buen trabajo!',
                'Tu respuesta es correcta',
                'success'
            );

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Tu respuesta es incorrecta `,
            })
        }
        timer = setTimeout(() => {
            const nextQuestion = currentQuestion + 1;
            // setshowCorrect(null);
            if (nextQuestion < questions.length) {
                setCurrentQuestion(nextQuestion);
            } else {
                setShowScore(true);
            }
        }, 700)

    };

    return (
        <div className='app container'>
            {showScore ? (
                <div className='score-section d-flex flex-column '>
                    Obtuviste {score} de {questions.length}
                    <button onClick={() => handleReset()} >Intentar nuevamente</button>
                    <div className="answers d-flex justify-content-evenly w-100 row">
                        <div className="d-flex flex-column col-sm-6">
                            <p style={{ fontSize: "20px" }}>Tus Respuestas:</p>
                            <ul className='list-group' >
                                {
                                    yourAnswers.map((item) => <div key={nanoid()} className="list-group-item list-group-item-warning"> <li>{item}</li> </div>)
                                }
                            </ul>
                        </div>
                        <div className="d-flex flex-column  col-sm-6">
                            <p style={{ fontSize: "20px" }}> Respuestas correctas:</p>
                            <ul className='list-group '>
                                {correct2.map((item) => <li key={nanoid()} className="list-group-item list-group-item-success"> {item}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='question-section'>
                        <div className='question-count'>
                            <span>Question {currentQuestion + 1}</span>/{questions.length}
                        </div>
                        <div className='question-text'>{questions[currentQuestion].questionText}</div>
                    </div>
                    <div className='answer-section'>

                        {questions[currentQuestion].answerOptions.map((answerOption, index) => (
                            <button key={index} onClick={() => handleAnswerOptionClick(answerOption)}>{answerOption.answerText}</button>
                        ))}

                    </div>
                </>
            )}
        </div>
    );
}

export default QuizComponent
