import React from 'react';
import { useState } from 'react';
import { createRef } from 'react';

import { Table } from 'react-bootstrap';
import Pdf from 'react-to-pdf';
import { useForm } from '../hooks/useForm';
import TableComponent from './Table';
import logotipo from './logoBlack.png';
import ReactDatePicker from 'react-datepicker';
import { useCalificarState } from '../hooks/useCalificarState';
import { useCalificarStateEnA1 } from '../hooks/useCalificarStateENA1';

const CalificarEn = () => {
    const [nota, setNota] = useState(0);
    const [{ name, nameTeacher, group, comment }, handleInputchange] = useForm({
        name: "",
        nameTeacher: "",
        group: "",
        comment: ""
    });

    
    const {
        changeStateItem,
        calificarInfo } = useCalificarStateEnA1();

    const ref = createRef();
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ margin: "0 auto", marginLeft: "40px" }}>
            <div ref={ref} className="p-5">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <label htmlFor="name">Student’s name:</label>
                    <input

                        type="text"
                        placeholder='Escriba aqui el nombre del estudiante'
                        className="input_withoutB"
                        name="name"
                        value={name}
                        onChange={handleInputchange}
                        id="name"
                        autoComplete="off"
                    />
                </div>

                <div className="w-100">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="nameTeacher">Teacher's Name:</label>
                        <input
                            type="text"
                            placeholder='Escriba aqui el nombre del Profesor'
                            className="input_withoutB"
                            name="nameTeacher"
                            value={nameTeacher}
                            onChange={handleInputchange}
                            id="nameTeacher"
                            autoComplete="off"

                        />
                    </div>
                    <div className="d-flex justify-content-between">
                        <label htmlFor="group text-bold">Group #:</label>
                        <input
                            type="text"
                            placeholder='Aqui el grupo'
                            className="input_withoutB-small"
                            name="group"
                            value={group}
                            onChange={handleInputchange}
                            id="group"
                            autoComplete="off"

                        />

                    </div>
                    <div className="d-flex justify-content-between">
                            <label htmlFor="group text-bold">Level:</label>
                            <input
                                type="select"
                                placeholder='Nivel del estudiante'
                                className="input_withoutB-small"
                                autoComplete="off"
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <label htmlFor="group text-bold">Date:</label>
                            <div className='w-100'></div>
                            <div className='w-50'>
                                <ReactDatePicker
                                    className='input_withoutB'
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                        </div>
                </div>
                <TableComponent setNota={setNota} nota={nota} calificarInfo={calificarInfo} changeStateItem={changeStateItem}/>
                <Table striped bordered size='sm' style={{ maxWidth: "700px" }}>
                    <thead>
                        <tr>

                            <th>Points earned:</th>
                            <th>{`${nota}/16`}</th>
                        </tr>
                    </thead>
                </Table>
                <h1 className='text-center'>Grade: {`${((10 / 16) * nota).toString().substring(0,4)}`}</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <label htmlFor="group text-bold">Feedback:</label>
                        <textarea
                            placeholder='Sin comentarios'
                            className="textarea"
                            name="comment"
                            value={comment}
                            onChange={handleInputchange}
                            id="group"
                            cols="50"
                            rows="10"
                        ></textarea>
                    </div>
                    <img src={logotipo} alt="Logotipo de go go academy" className="logotipo" />
                </div>
            </div>
            <Pdf targetRef={ref} filename={`${name.split(" ").join("") ?? "Student"}Exam.pdf`}>
                {
                    ({ toPdf }) => <button className='button' onClick={toPdf} >Descargar Pdf</button>
                }
            </Pdf>
        </div>
    )
}
export default CalificarEn;
