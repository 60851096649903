const tourisme17 = [{
    questionText: "1- En 2017, il y a eu un nombre « record » de touristes. Que signifie « record » ?",
    answerOptions: [{
        answerText: "très important",
        isCorrect: true
    },
    {
        answerText: "Stable",
        isCorrect: false
    },
    {
        answerText: "en baisse",
        isCorrect: false
    },
    ]
},
{
    questionText: " 2- Ce nombre record de touristes concerne :",
    answerOptions: [{
        answerText: "une ville.",
        isCorrect: true
    },

    {
        answerText: "un pays.",
        isCorrect: false
    },
    {
        answerText: "le monde entier.",
        isCorrect: true
    },
    ]
},
{
    questionText: "3- En Europe, le nombre de touristes n’a jamais été aussi important depuis :",
    answerOptions: [{
        answerText: "la fin de la deuxième guerre mondiale en 1945.",
        isCorrect: false
    },
    {
        answerText: "les attentats du 11 septembre 2001.",
        isCorrect: false
    },
    {
        answerText: "la crise économique mondiale en 2010.",
        isCorrect: true
    },

    ]
},
{
    questionText: "4- Quels pays sont les plus visités dans le monde en 2017 ? ",
    answerOptions: [{
        answerText: "L’Italie et La Tunisie",
        isCorrect: false
    },
    {
        answerText: "La France et L’Espagne",
        isCorrect: true
    },
    {
        answerText: "La Grèce et  La Tunisie",
        isCorrect: false
    },
    ]
},
{
    questionText: "5- En 2017, la France a reçu :",
    answerOptions: [{
        answerText: "900 000 (neuf-cent-mille) touristes.",
        isCorrect: false
    },
    {
        answerText: "9 000 000 (neuf millions) de touristes.",
        isCorrect: false
    },

    {
        answerText: "90 000 000 (quatre-vingt-dix millions) de touristes",
        isCorrect: true
    },
    ]
},


]

export {
    tourisme17
};