import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestorage } from "../firebase/firebaseConfig";
import moment from "moment";

export const handleGetLevelAndRol = async (uid) => {
    const docuRef = doc(firestorage, `users/${uid}`);
    const result = await getDoc(docuRef);
    if (result.exists()) {
        const rolAndlevel = result.data();
        await setDoc(docuRef, {
            ...rolAndlevel, lastLogin: moment().format("LLLL"), 
        })
        return rolAndlevel;
    } else {
        return {
            level: null,
            rol: null
        }
    }
}