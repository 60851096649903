import React, { useEffect } from 'react'
import { getPromo } from '../Components/utils/Path';
import { UseFetch } from './useRequest';
import Swal from 'sweetalert2';
export const UseShowAlert = () => {
    const [responsePromo, fetchPromo, fetchPromoFromFirebase] = UseFetch();
    useEffect(() => {
        fetchPromoFromFirebase(getPromo(), false);
    }, []);

    useEffect(() => {
        let promoAnterion = localStorage.getItem('promoID') ?? "";
        if (responsePromo.isSuccess) {
            console.log(responsePromo.response)
            console.log(promoAnterion);
            if (responsePromo.response.idPromo.toString() !== promoAnterion.toString() || promoAnterion === "") {
                Swal.fire({
                    imageUrl: responsePromo.response.imagen,
                    backdrop: `rgba(63,61,86, 0.5)`,
                    allowOutsideClick: false,
                    confirmButtonColor: "#ff9900",
                    confirmButtonText: "Contáctanos",
                    showCancelButton: true,
                    cancelButtonColor: "#3f3d56",
                    cancelButtonText: "Quizá luego"
                }).then((result) => {
                    if (result.isConfirmed) {
                        let targetURL = 'https://bit.ly/academiadeidiomasv';
                        let ancla = document.createElement('a');
                        ancla.href = targetURL;
                        ancla.target = "_blank"
                        document.body.appendChild(ancla);
                        ancla.click();
                    }
                })
                localStorage.setItem('promoID', JSON.stringify(responsePromo.response.idPromo))
            }
        }
    }, [responsePromo]);
}
